import React, { useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { MainContent, TableCard } from "@vms-admin/components";
import { approveVoucher, fetchVoucher } from "../../redux/actions";
import {
  dismissToast,
  formatDate,
  formatTime,
  somethignWentWrong,
} from "../../helpers";
import toast from "react-hot-toast";
import VoucherDetailsLoader from "./voucher-details-loader";

export const VoucherDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const voucher = useSelector(({ voucher }) => voucher.voucher);
  const voucherLoading = useSelector(({ voucher }) => voucher.voucherLoading);
  const userRole = useSelector(({ auth }) => auth.role);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const isAdminMerchantUser = userRole.is_merchant_user && userInfo.user_admin;
  const isAdminBrandUser = userRole.is_brand_user && userInfo.user_admin;

  const showApproveButton = useMemo(
    () =>
      userRole.is_brand ||
      userRole.is_merchant ||
      isAdminMerchantUser ||
      isAdminBrandUser,
    [userRole, isAdminMerchantUser, isAdminBrandUser]
  );

  const handleApproveVoucher = useCallback(
    (id) => {
      dismissToast();
      dispatch(approveVoucher(id))
        .then((res) => {
          if (res.status === 200) {
            toast.success("Voucher approved Successfully");
            dispatch(fetchVoucher(id));
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchVoucher(id));
  }, [dispatch, id]);

  return (
    <MainContent>
      {voucherLoading ? (
        <VoucherDetailsLoader />
      ) : (
        <div className="cards_wrapper">
          <TableCard
            title={` ${voucher?.voucher_set_name} Details`}
            btnText="Approve"
            btnAction={() => handleApproveVoucher(id)}
            hasAction={showApproveButton && !voucher?.approved}
          >
            <tbody>
              <tr>
                <td>
                  <strong>Voucher UID</strong>
                </td>
                <td>{voucher?.voucher_set_id}</td>
              </tr>
              <tr>
                <td>
                  <strong>Voucher Name</strong>
                </td>
                <td>{voucher?.voucher_set_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Voucher Description</strong>
                </td>
                <td>{voucher?.description}</td>
              </tr>
              <tr>
                <td>
                  <strong>Starts at</strong>
                </td>
                <td>
                  {formatDate(voucher?.start_datetime)}{" "}
                  {formatTime(voucher?.start_datetime)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Ends at</strong>
                </td>
                <td>
                  {formatDate(voucher?.end_datetime)}{" "}
                  {formatTime(voucher?.end_datetime)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Exclude Holidays</strong>
                </td>
                <td>
                  {voucher?.exclude_holidays ? (
                    <span className="label label-create">True</span>
                  ) : (
                    <span className="label label-delete">False</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Approved</strong>
                </td>
                <td>
                  {voucher?.approved ? (
                    <span className="label label-create">True</span>
                  ) : (
                    <span className="label label-delete">False</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Voucher Images</strong>
                </td>
                <td>
                  {voucher?.images?.map((image) => (
                    <img
                      style={{ width: 100, marginRight: 16 }}
                      src={image}
                      alt={image}
                    />
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Count</strong>
                </td>
                <td>{voucher?.count}</td>
              </tr>
            </tbody>
          </TableCard>
          <TableCard title="Merchant Details">
            <tbody>
              <tr>
                <td>
                  <strong>Merchant Name</strong>
                </td>
                <td>{voucher?.merchant?.merchant_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant Email Address</strong>
                </td>
                <td>{voucher?.merchant?.email}</td>
              </tr>
            </tbody>
          </TableCard>
          <TableCard title="Subsription Plans">
            <tbody>
              <tr>
                <td>
                  {voucher?.subscription_plans?.map(({ plan_name }) => (
                    <span className="label label-info">{plan_name}</span>
                  ))}
                </td>
              </tr>
            </tbody>
          </TableCard>
        </div>
      )}
    </MainContent>
  );
};

export default VoucherDetails;
