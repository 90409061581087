import rootReducer from "../reducers";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore } from "redux-persist";

// const persistConfig = {
//   key: "root",
//   storage,
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

let createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

// const store = createStoreWithMiddleware(persistedReducer);
const store = createStoreWithMiddleware(rootReducer);
export const persistor = persistStore(store);

export default store;
