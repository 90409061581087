import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";

import {
  Switch,
  Grid,
  Card,
  Button,
  MainContent,
  InputRangeField,
  Buttons,
} from "@vms-admin/components";
import {
  fetchMerchantAmenities,
  fetchMerchantAttributes,
  updateFormData,
} from "../../../redux/actions";
import { dismissToast, getBase64, somethignWentWrong } from "../../../helpers";
import API_URL from "../../../api/api.constants";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { useMemo } from "react";
import axios from "axios";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const CreateMerchantStepTwo = ({ prevStep }) => {
  const { register, handleSubmit } = useForm({ mode: "onChange" });
  const accessToken = localStorage.getItem("access-token");
  const merchant = useSelector(({ merchant }) => merchant);
  const [amenities, setAmenities] = useState(
    merchant?.formData?.amenities || []
  );
  const [attributes, setAttributes] = useState(
    merchant?.formData?.attributes || []
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { ref, inView } = useInView();

  const handleAmenitiesChange = ({ target: { checked } }, amenityId) => {
    if (checked && !amenities?.includes(amenityId)) {
      setAmenities([...amenities, amenityId]);
    } else {
      let remaining = amenities?.filter((amenity) => amenity !== amenityId);
      setAmenities(remaining);
    }
  };

  const handleAttributeChange = ({ target: { checked } }, attributeId) => {
    if (checked && !attributes?.includes(attributeId)) {
      setAttributes([...attributes, attributeId]);
    } else {
      let remaining = attributes?.filter(
        (attribute) => attribute !== attributeId
      );
      setAttributes(remaining);
    }
  };
  const [openingHours, setOpeningHours] = useState({
    Saturday: { min: 0, max: 1 },
    Sunday: { min: 0, max: 1 },
    Monday: { min: 0, max: 1 },
    Tuesday: { min: 0, max: 1 },
    Wednesday: { min: 0, max: 1 },
    Thursday: { min: 0, max: 1 },
    Friday: { min: 0, max: 1 },
  });

  const handleOpeningHoursChange = (value, name) =>
    setOpeningHours({ ...openingHours, [name]: { ...value } });

  const OpeningHoursData = useMemo(
    () =>
      weekdays.map((weekday) => ({
        name: weekday,
        value: {
          close: openingHours[weekday].max,
          open: openingHours[weekday].min,
        },
      })),
    [openingHours]
  );

  const createMerchant = useCallback(async () => {
    dismissToast();
    const image = await getBase64(merchant?.formData?.logo[0]);
    axios(API_URL.merchants, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        merchant_name: merchant.formData.name,
        category_id: merchant.formData.category.id,
        email: merchant.formData.email,
        country: merchant.formData.countryName?.value,
        city: merchant.formData.cityName?.value,
        brand_id: merchant.formData.brandName.id,
        telephone: merchant.formData.mobileNumber,
        po_box: merchant.formData.poBox,
        subscription_plan_id: merchant.formData?.plans?.id,
        description: merchant.formData.desc,
        address: merchant.formData.address,
        lat: merchant.formData.lat,
        long: merchant.formData.lng,
        image,
        amenities: amenities?.map((amenity) => ({ id: amenity })),
        attributes: attributes?.map((attribute) => ({ id: attribute })),
        opening_hours: OpeningHoursData,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          setTimeout(() => history.push("/merchant-management"), [2000]);
          toast.success(
            `${merchant.formData.name} has been Created Successfully`
          );
          dispatch(updateFormData({}));
        }
        if (res.status === 409) {
          toast.error("A merchant with the same email address already exists");
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch(() => somethignWentWrong());
  }, [
    accessToken,
    history,
    merchant,
    OpeningHoursData,
    dispatch,
    amenities,
    attributes,
  ]);

  useEffect(() => {
    dispatch(fetchMerchantAmenities());
    dispatch(fetchMerchantAttributes());
  }, [dispatch]);

  return (
    <MainContent>
      <form onSubmit={handleSubmit(createMerchant)}>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Merchant Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons>
                <Button
                  variant="secondary"
                  type="button"
                  handleClick={prevStep}
                >
                  Back
                </Button>
                <Button type="submit" variant="primary">
                  Create Merchant
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        <div className="cards_wrapper">
          <Card title="Merchant Amenities">
            <Grid columns={2}>
              {merchant?.amenities?.map(
                ({ amenity_name, amenity_icon, id }) => (
                  <Switch
                    handleChange={(e) => handleAmenitiesChange(e, id)}
                    name={amenity_name}
                    icon={amenity_icon}
                    register={register}
                    label={amenity_name}
                    key={id}
                    defaultValue={merchant?.formData?.amenities?.includes(id)}
                  />
                )
              )}
            </Grid>
          </Card>
          <Card title="Merchant Attributes">
            <Grid columns={2}>
              {merchant?.attributes?.map(
                ({ attribute_name, attribute_icon, id }) => (
                  <Switch
                    label={attribute_name}
                    icon={attribute_icon}
                    register={register}
                    name={attribute_name}
                    key={id}
                    handleChange={(e) => handleAttributeChange(e, id)}
                    defaultValue={merchant?.formData?.attributes?.includes(id)}
                  />
                )
              )}
            </Grid>
          </Card>
          <Card title="Opening hours">
            {weekdays.map((weekday, index) => (
              <InputRangeField
                key={index}
                step={1}
                maxValue={24}
                minValue={0}
                label={weekday}
                name={weekday.toLowerCase()}
                handleChange={(value) =>
                  handleOpeningHoursChange(value, weekday)
                }
                value={openingHours[weekday]}
              />
            ))}
          </Card>
        </div>
      </form>
    </MainContent>
  );
};

export default CreateMerchantStepTwo;
