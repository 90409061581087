import { motion } from "framer-motion";
import styled from "styled-components";

export const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

export const Modal = styled(motion.div)`
  border-color: #707070;
  background: white;
  border-radius: 12px;
  width: 600px;
  max-width: 100%;
  margin: auto;
`;

export const Title = styled.h3`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  padding: ${(props) => props.theme.space[3]}px
    ${(props) => props.theme.space[4]}px;
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  font-weight: 500;
`;

export const Body = styled.div`
  padding: ${(props) => props.theme.space[3]}px
    ${(props) => props.theme.space[4]}px;
`;
