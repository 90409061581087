import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import { merchantSchema } from "../../../schema";
import {
  Input,
  Button,
  Map,
  UploadField,
  Textarea,
  ErrorMessage,
  Card,
  MainContent,
  Grid,
  Buttons,
} from "@vms-admin/components";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchCountries,
  fetchCountryInfo,
  fetchMerchant,
  fetchBrandCategories,
  updateFormData,
  fetchMerchantCategories,
  fetchBrands,
} from "../../../redux/actions";
import { getCountryOptions, getCityOptions } from "@vms-admin/utils";
import { getBrandOptions, getCategoryOptions } from "../../../utils";
import EditMerchantLoader from "./edit-merchant-loader";

export const EditMerchant = ({ nextStep }) => {
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const { id } = useParams();
  const brands = useSelector(({ brands }) => brands.brands);
  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );
  const { ref, inView } = useInView();

  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(merchantSchema),
  });
  const merchant = useSelector(({ merchant }) => merchant);

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
    },
    [setValue, dispatch]
  );

  const saveData = useCallback(
    (data) => {
      const formData = { ...data, address, lat, lng };
      dispatch(updateFormData(formData));
      nextStep();
    },
    [dispatch, nextStep, address, lat, lng]
  );

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCountryInfo(merchant?.merchant?.country?.id));
    dispatch(fetchBrandCategories());
  }, [dispatch, merchant]);

  useEffect(() => {
    dispatch(fetchMerchant(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchMerchantCategories());
    dispatch(fetchBrands());
  }, [dispatch]);

  useEffect(() => {
    setLat(Number(merchant?.merchant?.lat));
    setLng(Number(merchant?.merchant?.long));
  }, [merchant?.merchant?.lat, merchant?.merchant?.long]);

  useEffect(() => {
    if (merchant?.formData.name) {
      setValue("name", merchant?.formData.name);
    } else {
      setValue("name", merchant?.merchant?.merchant_name);
    }

    if (merchant?.formData.email) {
      setValue("email", merchant?.formData.email);
    } else {
      setValue("email", merchant?.merchant?.email);
    }
    if (merchant?.formData.mobileNumber) {
      setValue("mobileNumber", merchant?.formData.mobileNumber);
    } else {
      setValue("mobileNumber", merchant?.merchant?.telephone);
    }

    if (merchant?.formData.countryName) {
      setValue("countryName", {
        value: merchant?.formData?.countryName?.value,
        label: merchant?.formData?.countryName?.label,
      });
    } else {
      setValue("countryName", {
        value: merchant?.merchant?.country,
        label: merchant?.merchant?.country,
      });
    }

    if (merchant?.formData.cityName) {
      setValue("cityName", {
        value: merchant?.formData?.cityName?.value,
        label: merchant?.formData?.cityName?.label,
      });
    } else {
      setValue("cityName", {
        value: merchant?.merchant?.city,
        label: merchant?.merchant?.city,
      });
    }

    if (merchant?.formData.category) {
      setValue("category", {
        value: merchant?.formData?.category?.value,
        label: merchant?.formData?.category?.label,
      });
    } else {
      setValue("category", {
        value: merchant?.merchant?.merchant_category?.category_name,
        label: merchant?.merchant?.merchant_category?.category_name,
      });
    }

    if (merchant?.formData.brandName) {
      setValue("brandName", {
        value: merchant?.formData?.brandName?.value,
        label: merchant?.formData?.brandName?.label,
        id: merchant?.formData?.brandName?.id,
      });
    } else {
      setValue("brandName", {
        value: merchant?.merchant?.brand?.brand_name,
        label: merchant?.merchant?.brand?.brand_name,
        id: merchant?.merchant?.brand?.id,
      });
    }

    if (merchant?.formData.poBox) {
      setValue("poBox", merchant?.formData.poBox);
    } else {
      setValue("poBox", merchant?.merchant?.po_box);
    }
    if (merchant?.formData.address) {
      setValue("address", merchant?.formData.address);
    } else {
      setValue("address", merchant?.merchant?.address);
    }
    if (merchant?.formData.desc) {
      setValue("desc", merchant?.formData.desc);
    } else {
      setValue("desc", merchant?.merchant?.description);
    }
  }, [merchant, setValue]);

  return (
    <MainContent>
      <form onSubmit={handleSubmit(saveData)} noValidate>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Merchant Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Link to="/merchant-management" className="btn btn-secondary">
                  Back
                </Link>
                <Button type="submit" variant="primary">
                  Next
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        {merchant.merchantLoading ? (
          <EditMerchantLoader />
        ) : (
          <div className="cards_wrapper">
            <Card title="Edit Merchant">
              <Grid columns={2}>
                <Input
                  label="Merchant Name"
                  type="text"
                  placeholder="Merchant Name"
                  name="name"
                  register={register}
                  errors={errors}
                />
                <Input
                  label="Email Address"
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  register={register}
                  errors={errors}
                />
              </Grid>
              <Grid columns={3}>
                <div className="input_holder">
                  <label>Merchant Category</label>
                  <Controller
                    name="category"
                    errors={errors}
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        options={getCategoryOptions(merchant?.categories)}
                        placeholder="Select Merchant Category"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["category"] && (
                    <ErrorMessage text="Category is Required" />
                  )}
                </div>
                <div className="input_holder">
                  <label>Brand Name</label>
                  <Controller
                    name="brandName"
                    errors={errors}
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        placeholder="Select Brand Name"
                        options={getBrandOptions(brands)}
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["brandName"] && (
                    <ErrorMessage text="Brand Name is Required" />
                  )}
                </div>
                <Input
                  label="Merchant Mobile Number"
                  type="tel"
                  placeholder="Merchant Mobile Number"
                  name="mobileNumber"
                  register={register}
                  errors={errors}
                />
              </Grid>

              <Textarea
                label="Description"
                type="text"
                placeholder="Description"
                name="desc"
                register={register}
                errors={errors}
              />
              <UploadField
                label="Merchant Logo"
                name="logo"
                errors={errors}
                register={register}
                image={merchant?.merchant?.image}
              />
            </Card>
            <Card title="Location">
              <Grid columns={3}>
                <div className="input_holder">
                  <label>Country Name</label>
                  <Controller
                    name="countryName"
                    errors={errors}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        menuPortalTarget={document.body}
                        options={getCountryOptions(
                          countryManagementState.countries
                        )}
                        placeholder="Select Country"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        value={value ? value : ""}
                      />
                    )}
                  />
                  {errors && errors["countryName"] && (
                    <ErrorMessage text="Country is Required" />
                  )}
                </div>
                <div className="input_holder">
                  <label>City Name</label>
                  <Controller
                    name="cityName"
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        options={getCityOptions(
                          countryManagementState?.countryInfo?.cities
                        )}
                        isLoading={countryManagementState?.countryInfoLoading}
                        loadingMessage={() => "Loading..."}
                        placeholder="Select City"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["cityName"] && (
                    <ErrorMessage text="City is Required" />
                  )}
                </div>
                <Input
                  label="Merchant P.O. Box"
                  type="text"
                  placeholder="Merchant P.O. Box"
                  name="poBox"
                  register={register}
                  errors={errors}
                />
              </Grid>
              <Map
                addressInfo={(address, lat, lng) => {
                  if (address) {
                    setAddress(address);
                  } else {
                    setAddress(merchant?.merchant?.address);
                  }
                  if (lat) {
                    setLat(lat);
                  }

                  if (lng) {
                    setLng(lng);
                  }
                }}
                address={merchant?.merchant?.address}
                userLat={Number(merchant?.merchant?.lat)}
                userLng={Number(merchant?.merchant?.long)}
              />
            </Card>
          </div>
        )}
      </form>
    </MainContent>
  );
};

export default EditMerchant;
