import React from "react";

import { ErrorMessage } from "@vms-admin/components";

export const Input = ({
  label,
  type,
  name,
  value,
  placeholder,
  errors,
  register,
  disabled,
  defaultValue,
  icon,
  ...rest
}) => (
  <div
    className={`input_holder ${errors && errors[name] ? `error` : ""}`}
    {...rest}
  >
    <label>
      {label}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        disabled={disabled}
        defaultValue={defaultValue}
        {...register(name, {
          valueAsNumber: type === "number" ? true : false,
        })}
        style={{
          marginTop: label ? "10px" : 0,
        }}
      />
      {icon && <span>{icon}</span>}
    </label>

    {errors && errors[name] && (
      <ErrorMessage text={errors[name].message}></ErrorMessage>
    )}
  </div>
);

export default Input;
