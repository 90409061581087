const theme = {
  colors: {
    black: "#333",
    white: "#fff",
    red: "#cd0303",
    lightGray: "#F7F7F7",
    gray: "#EAEAEA",
    primary: "#3CD17F",
    secondary: "#4B42AE",
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 32, 50, 64],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
};

export default theme;
