import React from "react";

export const Search = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="noun-search-1122744" transform="translate(-105.319 -130.199)">
      <path
        id="Path_25675"
        data-name="Path 25675"
        d="M217.152,132.33a7.277,7.277,0,0,0-10.7,9.851l-3.173,3.173.846.846,3.173-3.173a7.189,7.189,0,0,0,4.714,1.722,7.267,7.267,0,0,0,5.137-12.419Zm-.846,9.428a6.068,6.068,0,1,1,0-8.582A6.062,6.062,0,0,1,216.305,141.757Z"
        transform="translate(-97.963 0)"
        fill="#acacac"
      />
    </g>
  </svg>
);
