import React, { useState } from "react";
import { useCallback } from "react";
import CreateMerchantStepOne from "./step-one.jsx";
import CreateMerchantStepTwo from "./step-two.jsx";

export const CreateMerchantForm = () => {
  const [formStep, setFormStep] = useState(1);

  const goToNext = useCallback(() => setFormStep(formStep + 1), [formStep]);
  const goToPrev = useCallback(() => setFormStep(formStep - 1), [formStep]);

  return (
    <>
      {formStep === 1 && <CreateMerchantStepOne nextStep={goToNext} />}
      {formStep === 2 && <CreateMerchantStepTwo prevStep={goToPrev} />}
    </>
  );
};

export default CreateMerchantForm;
