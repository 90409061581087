import * as Yup from "yup";

// eslint-disable-next-line
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const numberOnlyRegExp = /^[0-9]*$/;
const floatOnlyRegExp = /^[+-]?([0-9]*[.])?[0-9]+$/;

export const loginSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Please enter at least 8 characters"),
});

export const createCategorySchema = Yup.object().shape({
  categoryName: Yup.string().required("Category Name is required"),
});

export const editCategorySchema = Yup.object().shape({
  editCategoryName: Yup.string().required("Category Name is required"),
  // categoryIcon: Yup.array().nullable().required("Category Icon is required"),
});

export const resendEmailSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email can not be blank")
    .email("Please enter a valid email address"),
});

export const clientSchema = Yup.object().shape({
  name: Yup.string().required("Client Name is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  mobileNumber: Yup.string()
    .required("Client Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid"),
  countryName: Yup.object().required("Country Name is required"),
  cityName: Yup.object().required("City Name is required"),
  // clientAddress: Yup.string().required("Client Address is required"),
  poBox: Yup.string().required("Client P.O. Box is required"),
  category: Yup.object().required("Client Category is required"),
});

export const createClientSchema = Yup.object().shape({
  name: Yup.string().required("Client Name is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  mobileNumber: Yup.string()
    .required("Client Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid"),
  countryName: Yup.object().required("Country Name is required"),
  cityName: Yup.object().required("City Name is required"),
  // clientAddress: Yup.string().required("Client Address is required"),
  poBox: Yup.string().required("Client P.O. Box is required"),
  category: Yup.object().required("Client Category is required"),
  logo: Yup.mixed()
    .test("required", "Client Logo is required", (value) => value.length > 0)
    .test("fileSize", "Image Size is too large", (value) => {
      return value.length && value[0].size <= 5242880;
    }),
});

export const brandSchema = Yup.object().shape({
  name: Yup.string().required("Client Name is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  mobileNumber: Yup.string()
    .required("Brand Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid"),
  countryName: Yup.object().required("Country Name is required"),
  clientName: Yup.object().required("Client Name is required"),
  cityName: Yup.object().required("City Name is required"),
  // clientAddress: Yup.string().required("Client Address is required"),
  poBox: Yup.string().required("Brand P.O. Box is required"),
  category: Yup.object().required("Brand Category is required"),
});

export const createBrandSchema = Yup.object().shape({
  name: Yup.string().required("Client Name is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  mobileNumber: Yup.string()
    .required("Brand Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid"),
  countryName: Yup.object().required("Country Name is required"),
  clientName: Yup.object().required("Client Name is required"),
  cityName: Yup.object().required("City Name is required"),
  // clientAddress: Yup.string().required("Client Address is required"),
  poBox: Yup.string().required("Brand P.O. Box is required"),
  category: Yup.object().required("Brand Category is required"),
  logo: Yup.mixed()
    .test("required", "Brand Logo is required", (value) => value.length > 0)
    .test("fileSize", "Image Size is too large", (value) => {
      return value.length && value[0].size <= 5242880;
    }),
});

export const merchantSchema = Yup.object().shape({
  name: Yup.string().required("Merchant Name is required"),
  desc: Yup.string().required("Description is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  mobileNumber: Yup.string()
    .required("Merchant Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid"),
  countryName: Yup.object().required("Country Name is required"),
  cityName: Yup.object().required("City Name is required"),
  // clientAddress: Yup.string().required("Client Address is required"),
  poBox: Yup.string().required("Client P.O. Box is required"),
  category: Yup.object().required("Merchant Category is required"),
});

export const createMerchantSchema = Yup.object().shape({
  name: Yup.string().required("Merchant Name is required"),
  desc: Yup.string().required("Description is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  mobileNumber: Yup.string()
    .required("Merchant Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid"),
  countryName: Yup.object().required("Country Name is required"),
  cityName: Yup.object().required("City Name is required"),
  // clientAddress: Yup.string().required("Client Address is required"),
  poBox: Yup.string().required("Client P.O. Box is required"),
  category: Yup.object().required("Merchant Category is required"),
  brandName: Yup.object().required("Brand Name is required"),
  logo: Yup.mixed()
    .test("required", "Merchant Logo is required", (value) => value.length > 0)
    .test("fileSize", "Image Size is too large", (value) => {
      return value.length && value[0].size <= 5242880;
    }),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required"),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(8, "Please enter at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .min(8, "Please enter at least 8 characters"),
});

export const planSchema = Yup.object().shape({
  planName: Yup.string().required("Plan Name is required"),
  planDesc: Yup.string().required("Plan Description is required"),
  planFeatures: Yup.string().required("Plan Features is required"),
  maxMembers: Yup.string()
    .required("Max Members is required")
    .matches(numberOnlyRegExp, "Max members should be number only"),
  perDay: Yup.string()
    .required("Price per day is required")
    .matches(floatOnlyRegExp, "Price per day should be number only"),
  perMonth: Yup.string()
    .required("Price per month is required")
    .matches(floatOnlyRegExp, "Price per month should be number only"),
  perYear: Yup.string()
    .required("Price per year is required")
    .matches(floatOnlyRegExp, "Price per year should be number only"),
  countryName: Yup.object().required("Country Name is required"),
  cityName: Yup.object().required("City Name is required"),
  currencyName: Yup.object().required("Currency is required"),
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
});

export const createPlanSchema = Yup.object().shape({
  planName: Yup.string().required("Plan Name is required"),
  planDesc: Yup.string().required("Plan Description is required"),
  planFeatures: Yup.string().required("Plan Features is required"),
  maxMembers: Yup.string()
    .required("Max Members is required")
    .matches(numberOnlyRegExp, "Max members should be number only"),
  perDay: Yup.string()
    .required("Price per day is required")
    .matches(floatOnlyRegExp, "Price per day should be number only"),
  perMonth: Yup.string()
    .required("Price per month is required")
    .matches(floatOnlyRegExp, "Price per month should be number only"),
  perYear: Yup.string()
    .required("Price per year is required")
    .matches(floatOnlyRegExp, "Price per year should be number only"),
  countryName: Yup.object().required("Country Name is required"),
  cityName: Yup.object().required("City Name is required"),
  currencyName: Yup.object().required("Currency is required"),
  image: Yup.mixed()
    .test("required", "Plan Image is required", (value) => value.length > 0)
    .test("fileSize", "Image Size is too large", (value) => {
      return value.length && value[0].size <= 5242880;
    }),
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
});

export const AdminVoucherSchema = Yup.object().shape({
  voucherName: Yup.string().required("Voucher Name is required"),
  voucherDesc: Yup.string().required("Voucher Description is required"),
  voucherCount: Yup.string().required("Voucher Count is required"),
  pushTo: Yup.object().required("Please select where to push this voucher"),
  subscriptionPlans: Yup.array().required(),
  voucherCategory: Yup.string().required("voucher Category is required"),
});

export const voucherSchema = Yup.object().shape({
  voucherName: Yup.string().required("Voucher Name is required"),
  voucherDesc: Yup.string().required("Voucher Description is required"),
  voucherCount: Yup.string().required("Voucher Count is required"),
  pushTo: Yup.object().required("Please select where to push this voucher"),
  voucherCategory: Yup.string().required("voucher Category is required"),
  merchantName: Yup.object().required("Merchant Name is required"),
  getSubscriptionPlan: Yup.string().required("Please select a filter"),
  subscriptionPlans: Yup.array().required(),
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
});

export const editVoucherSchema = Yup.object().shape({
  voucherName: Yup.string().required("Voucher Name is required"),
  voucherDesc: Yup.string().required("Voucher Description is required"),
  voucherCount: Yup.string().required("Voucher Count is required"),
  pushTo: Yup.object().required("Please select where to push this voucher"),
  voucherCategory: Yup.string().required("voucher Category is required"),
  subscriptionPlans: Yup.array().required(),
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
});

export const merchantVoucherSchema = Yup.object().shape({
  voucherName: Yup.string().required("Voucher Name is required"),
  voucherDesc: Yup.string().required("Voucher Description is required"),
  voucherCount: Yup.string().required("Voucher Count is required"),
  pushTo: Yup.object().required("Please select where to push this voucher"),
  voucherCategory: Yup.string().required("voucher Category is required"),
  getSubscriptionPlan: Yup.string().required("Please select a filter"),
  subscriptionPlans: Yup.array().required(),
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
});

export const userSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
});

export const createUserSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  userAvatar: Yup.mixed()
    .test("required", "User Avatar is required", (value) => value.length > 0)
    .test("fileSize", "Image Size is too large", (value) => {
      return value.length && value[0].size <= 5242880;
    }),
});

export const createVoucherSchema = Yup.object().shape({
  voucherName: Yup.string().required("Voucher Name is required"),
  clientName: Yup.object().required("Client Name is required"),
  brandName: Yup.object().required("Brand Name is required"),
  merchantName: Yup.object().required("Merchant Name is required"),
  voucherDesc: Yup.string().required("Voucher Description is required"),
});

export const registerSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  emailAddress: Yup.string()
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  contactNumber: Yup.string()
    .required("Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid"),
  userImage: Yup.mixed()
    .test("required", "User Image is required", (value) => value.length > 0)
    .test("fileSize", "Image Size is too large", (value) => {
      return value.length && value[0].size <= 5242880;
    }),
  countryName: Yup.object().required("Country Name is required"),
  cityName: Yup.object().required("City Name is required"),
});

export const createCitySchema = Yup.object().shape({
  cityName: Yup.string().required("City Name is required"),
  cityCode: Yup.string().required("City Code is required"),
});
