import styled from "styled-components";

export const HelperTextWrapper = styled.div`
  margin-top: 20px;
  max-width: 950px;
`;

export const HelperText = styled.span`
  display: block;
  color: #8e8e8e;
  line-height: 20px;
  margin-bottom: ${(props) => props.theme.space[1]}px;
  a {
    color: ${(props) => props.theme.colors.secondary};
  }
  span {
    color: ${(props) => props.theme.colors.black};
  }
`;
