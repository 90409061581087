import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";

import {
  Grid,
  Pagination,
  TableCard,
  MainContent,
  Modal,
  Input,
  Button,
  Buttons,
} from "@vms-admin/components";
import { usePagination } from "../../hooks/usePagination";
import {
  createCity,
  deleteCity,
  fetchCountries,
  fetchCountryInfo,
} from "../../redux/actions";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { dismissToast, somethignWentWrong } from "../../helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCitySchema } from "../../schema";

export const CountryManagement = () => {
  const [seleectedCountryCode, setSelectedCountryCode] = useState({
    id: 2,
    country_code: "AE",
  });
  const [isCityModaOpen, setIsCityModalOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedCountry = useCallback(
    (id) => dispatch(fetchCountryInfo(id)),
    [dispatch]
  );

  const countryManagement = useSelector(
    ({ countryManagement }) => countryManagement
  );

  const {
    goToNextPage,
    goToPrevPage,
    pagesArray,
    firstIndex,
    lastIndex,
    canGoNext,
    canGoPrev,
    pageDecrementBtn,
    pageIncementBtn,
  } = usePagination(countryManagement.countries, 10);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(createCitySchema) });

  const toggleCityModal = useCallback(() => {
    setIsCityModalOpen(!isCityModaOpen);
    reset();
  }, [isCityModaOpen, reset]);

  const handleCreateCity = useCallback(
    (data) => {
      dismissToast();
      dispatch(createCity(data, seleectedCountryCode))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchCountryInfo(seleectedCountryCode.id));
            toggleCityModal();
            toast.success("City has been created successfully");
            reset();
            dismissToast();
          }
          res.status === 409 && toast.error("City Name already exists");
          res.status === 500 && somethignWentWrong();
        })
        .catch(() => {
          somethignWentWrong();
        });
    },
    [dispatch, seleectedCountryCode, reset, toggleCityModal]
  );

  const handleDeleteCity = useCallback(
    (id) =>
      dispatch(deleteCity(id, seleectedCountryCode))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchCountryInfo(seleectedCountryCode.id));
            toast.success("City has been deleted Successfully");
            dismissToast();
          }
        })
        .catch(() => somethignWentWrong()),
    [dispatch, seleectedCountryCode]
  );

  useEffect(() => {
    dispatch(fetchCountryInfo(2));
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
    <MainContent>
      <Grid columns={2}>
        <div>
          <TableCard title="Countries">
            <tbody>
              {countryManagement?.countries
                .slice(firstIndex, lastIndex)
                ?.map(({ country_code, country_name, id }) => (
                  <motion.tr
                    key={id}
                    countryId={id}
                    onClick={() => {
                      selectedCountry(id);
                      setSelectedCountryCode({ id, country_code });
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <td>{country_code}</td>
                    <td>{country_name}</td>
                  </motion.tr>
                ))}
            </tbody>
          </TableCard>
          <Pagination
            canGoNext={canGoNext}
            canGoPrev={canGoPrev}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            pagesArray={pagesArray}
            pageDecrementBtn={pageDecrementBtn}
            pageIncementBtn={pageIncementBtn}
          />
        </div>
        <div>
          <TableCard
            title="Cities"
            btnText="Create"
            btnAction={toggleCityModal}
            hasAction
          >
            {!countryManagement?.countryInfo?.cities?.length && (
              <tr>
                <td className="empty_list_message">No Cities found</td>
              </tr>
            )}
            <tbody>
              {countryManagement?.countryInfo?.cities?.length > 0 &&
                countryManagement?.countryInfo?.cities?.map(
                  ({ city_code, city_name, id }) => (
                    <motion.tr
                      key={id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <td>{city_code}</td>
                      <td>{city_name}</td>
                      <td>
                        <Button
                          variant="danger"
                          handleClick={() => handleDeleteCity(id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </motion.tr>
                  )
                )}
            </tbody>
          </TableCard>
        </div>
        <div>
          <TableCard title="Currencies">
            {!countryManagement?.countryInfo?.currency?.length && (
              <tr>
                <td className="empty_list_message">No Currencies found</td>
              </tr>
            )}
            <tbody>
              {countryManagement?.countryInfo?.currency?.length > 0 &&
                countryManagement?.countryInfo?.currency?.map(
                  ({ currency_iso_code, currency_name, id }) => (
                    <motion.tr
                      key={id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <td>{currency_iso_code}</td>
                      <td>{currency_name}</td>
                    </motion.tr>
                  )
                )}
            </tbody>
          </TableCard>
        </div>
      </Grid>
      {isCityModaOpen && (
        <Modal title="Add City">
          <form onSubmit={handleSubmit(handleCreateCity)}>
            <Input
              type="text"
              placeholder="City Name"
              label="City Name"
              register={register}
              name="cityName"
              errors={errors}
            />
            <Input
              type="text"
              placeholder="City Code"
              label="City Code"
              register={register}
              name="cityCode"
              errors={errors}
            />
            <Buttons>
              <Button
                variant="secondary"
                type="button"
                handleClick={toggleCityModal}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Create
              </Button>
            </Buttons>
          </form>
        </Modal>
      )}
    </MainContent>
  );
};

export default CountryManagement;
