import React from "react";
import { motion } from "framer-motion";
import { routingAnimation } from "../../utils/animations";

export const MainContent = ({ children }) => (
  <motion.main
    className="main_page"
    variants={routingAnimation}
    initial={routingAnimation.hidden}
    animate={routingAnimation.visible}
    transition={routingAnimation.transition}
    style={{ marginBottom: 100 }}
  >
    <div className="content inner_dashboard_container">
      <div className="page-content">{children}</div>
    </div>
  </motion.main>
);

export default MainContent;
