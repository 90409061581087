import React, { useEffect, useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";

import { Button, Input, Header } from "@vms-admin/components";
import { ShowPassword, HidePassword } from "@vms-admin/icons";
import { loginUser } from "../../redux/actions";
import { loginSchema } from "../../schema";
import * as Styled from "./login.style";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { dismissToast, somethignWentWrong } from "../../helpers";
import API_URL from "../../api/api.constants";
import axios from "axios";
import { fetchUserInfo } from "../../redux/actions";

export const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userOverview, setUserOverview] = useState({});

  const [instance, setInstance] = useState("");
  const accessToken = localStorage.getItem("access-token");
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const togglePassword = useCallback(() => {
    if (passwordFieldType === "password") {
      setPasswordFieldType("text");
    } else {
      setPasswordFieldType("password");
    }
  }, [passwordFieldType]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(loginSchema),
  });

  const handleLogin = useCallback(
    (data) => {
      dismissToast();
      dispatch(loginUser(data.emailAddress, data.password))
        .then((res) => {
          setTimeout(() => toast.dismiss(), [2000]);
          if (res.status === 200) {
            dispatch(fetchUserInfo());
          } else if (res?.status === 500) {
            somethignWentWrong();
          } else if (res?.status === 401 || res?.status === 404) {
            toast.error("Email or password is incorrect");
          }
        })
        .catch(() => {
          somethignWentWrong();
        });
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (window.location.href.includes("incontrol")) {
      setInstance("in-control");
    }
    if (window.location.href.includes("rokit")) {
      setInstance("rokit");
    }
    if (window.location.href.includes("afritech")) {
      setInstance("starlink");
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      if (!userInfo?.role) {
        dispatch(fetchUserInfo());
      } else {
        if (userInfo.role === "customer") {
          history.push("/profile");
        } else {
          history.push("/dashboard-operational");
        }
      }
    }
  }, [accessToken, dispatch, history, userInfo]);

  return (
    <>
      <Styled.LoginPage className="login-page">
        <Header instance={instance} />
        <Styled.LoginWrapper className="login-wrapper">
          <Styled.IconWrapper>
            {instance === "starlink" ? (
              <>
                <svg
                  className="ml-[200px]"
                  id="starlink-logo"
                  width="732"
                  height="524"
                  version="1.1"
                  viewBox="0 0 1032 524"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <clipPath id="mask_x1">
                    <circle id="circle2" cx="275" cy="85" r="200" />
                  </clipPath>
                  <clipPath id="mask_x2">
                    <circle id="circle5" cx="230" cy="90" r="80" />
                  </clipPath>
                  <g
                    id="g853"
                    transform="matrix(3.3784 0 0 3.3784 -530.93 7.7532)"
                  >
                    <g id="logo">
                      <g id="x1" clip-path="url(#mask_x1)">
                        <path
                          id="path17"
                          class="logo_x"
                          d="m168.4 112.2-3.9 3.8h34.5c93-93.4 226.9-110 261-112.8v-0.1c-186.1 10.7-272.1 90.3-291.6 109.1"
                          visibility="inherit"
                        />
                      </g>
                      <g id="x2" clip-path="url(#mask_x2)" visibility="inherit">
                        <path
                          id="path20"
                          class="logo_x"
                          d="m263.2 116h34.1l-47.2-34.8c-5.7 3.9-11.6 8.3-17.2 12.7z"
                        />
                        <path
                          id="path22"
                          class="logo_x"
                          d="m200.9 44.6h-34.1l40 29.3c5.8-3.9 12.5-8.1 18.7-11.7z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </>
            ) : instance === "rokit" ? (
              <img
                src="/images/rokit-pattern.png"
                alt="rokit pattern"
                width="640"
              />
            ) : instance === "in-control" ? (
              <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                width="640"
                height="640"
                overflow="visible"
              >
                <linearGradient
                  id="gradient"
                  gradientUnits="userSpaceOnUse"
                  x1="0.6374"
                  y1="102.3673"
                  x2="204.6374"
                  y2="102.3673"
                >
                  <stop offset="0" style={{ stopColor: "#027FFF" }} />
                  <stop offset="0.12" style={{ stopColor: "#0692F9" }} />
                  <stop offset="0.38" style={{ stopColor: "#0EB7EE" }} />
                  <stop offset="0.62" style={{ stopColor: "#14D2E6" }} />
                  <stop offset="0.83" style={{ stopColor: "#18E2E2" }} />
                  <stop offset="1" style={{ stopColor: "#19E8E0" }} />
                </linearGradient>
                <motion.path
                  d="M0,50a50,50 0 1,0 100,0a50,50 0 1,0 -100,0"
                  fill="none"
                  strokeWidth={8}
                  stroke="url(#gradient)"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ ease: "easeOut", duration: 0.7 }}
                />
              </svg>
            ) : (
              <iframe
                src="/images/login-svg.html"
                title="login"
                width="655px"
                height="655px"
              />
            )}
          </Styled.IconWrapper>
          <Styled.Wrapper className="login-form">
            <Styled.Title>Log in</Styled.Title>
            <Styled.Subtitle>
              Enter your account details to login
            </Styled.Subtitle>
            <Styled.Form onSubmit={handleSubmit(handleLogin)}>
              <Input
                type="email"
                placeholder="E-mail address"
                label="Email"
                name="emailAddress"
                register={register}
                errors={errors}
              />
              <div className="password-field">
                <Input
                  type={passwordFieldType}
                  placeholder="Password"
                  label="Password"
                  name="password"
                  register={register}
                  errors={errors}
                />
                <div className="toggle-password" onClick={togglePassword}>
                  {passwordFieldType === "password" ? (
                    <ShowPassword />
                  ) : (
                    <HidePassword />
                  )}
                </div>
              </div>
              <Styled.Link>
                <Link to="/auth/forgot-password">Forgot Password?</Link>
              </Styled.Link>
              <div className="form_action">
                <div className="justify flex center">
                  <h5>
                    Don’t have an account? <Link to="/register">Sign up</Link>
                  </h5>
                  <Button type="submit" variant="primary">
                    Login
                  </Button>
                </div>
              </div>
            </Styled.Form>
          </Styled.Wrapper>
        </Styled.LoginWrapper>
      </Styled.LoginPage>
    </>
  );
};

export default Login;
