import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Input,
  Pagination,
  Modal,
  TableCard,
  MainContent,
} from "@vms-admin/components";
import { active, capitalize, formatDate, inActive } from "../../helpers";
import { usePagination } from "../../hooks/usePagination";
import { fetchAllUsers } from "../../redux/actions";

export const UserManagement = () => {
  const users = useSelector(({ allUsers }) => allUsers.allUsers);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const {
    goToNextPage,
    goToPrevPage,
    pagesArray,
    canGoNext,
    canGoPrev,
    currentPage,
    pageDecrementBtn,
    pageIncementBtn,
  } = usePagination(users?.data?.pages);

  const getFilteredUsers = useCallback(() => {
    if (!watch("search")) {
      return users?.data?.data;
    } else {
      return users?.filter((user) => user.email.includes(watch("search")));
    }
  }, [watch, users]);

  const modalActions = useMemo(
    () => [
      {
        title: "Submit",
        variant: "primary",
        onClick: () => console.log("Aaa"),
      },
      {
        title: "Cancel",
        variant: "default",
        onClick: () => setShowModal(false),
      },
    ],
    []
  );

  useEffect(
    () => dispatch(fetchAllUsers(currentPage)),
    [dispatch, currentPage]
  );

  return (
    <MainContent>
      <TableCard title="User Management">
        {!getFilteredUsers()?.length && (
          <tbody>
            <tr>
              <td className="empty_list_message">No Users found</td>
            </tr>
          </tbody>
        )}
        {getFilteredUsers()?.length > 0 && (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>Email Address</th>
                <th>Role</th>
                <th>Status</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              {getFilteredUsers()?.map(
                ({ email, role, confirmed, created_at }, index) => (
                  <tr key={email}>
                    <td>{index + 1}</td>
                    <td>{email}</td>
                    <td>{capitalize(role)}</td>
                    <td>
                      {confirmed ? active("Confirmed") : inActive("Pending")}
                    </td>
                    <td>{formatDate(created_at)}</td>
                  </tr>
                )
              )}
            </tbody>
          </>
        )}
      </TableCard>

      {users?.data?.pages > 1 && (
        <Pagination
          canGoNext={canGoNext}
          canGoPrev={canGoPrev}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          pagesArray={pagesArray}
          pageDecrementBtn={pageDecrementBtn}
          pageIncementBtn={pageIncementBtn}
        />
      )}
      {showModal && (
        <Modal
          title="Add New Client"
          showModal={showModal}
          actions={modalActions}
        >
          <form>
            <Input
              type="email"
              placeholder="Client Email"
              label="Client Email"
              name="clientEmail"
              register={register}
              errors={errors}
            />
          </form>
        </Modal>
      )}
    </MainContent>
  );
};

export default UserManagement;
