import { useState, useCallback, useMemo } from "react";
import styled from "styled-components";

const Item = styled.li`
  padding: 0 10px;
  cursor: pointer;
`;

export const usePagination = (pages) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxLimit, setMaxLimit] = useState(5);
  const [minLimit, setMinLimit] = useState(0);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const limit = 50;

  const lastIndex = useMemo(() => currentPage * limit, [currentPage, limit]);

  const firstIndex = useMemo(() => lastIndex - limit, [lastIndex, limit]);

  const canGoNext = () => pages !== currentPage;

  const canGoPrev = () => currentPage !== 1;

  const pagination = Array.from({ length: pages }, (_, i) => i + 1);

  const nextPagesArray = useCallback(() => {
    if (currentPage + 1 > maxLimit) {
      setMaxLimit(maxLimit + pageNumberLimit);
      setMinLimit(minLimit + pageNumberLimit);
    }
  }, [currentPage, minLimit, maxLimit, pageNumberLimit]);

  const goToNextPage = useCallback(() => {
    setCurrentPage(currentPage + 1);
    nextPagesArray();
  }, [currentPage, nextPagesArray]);

  const prevPagesArray = useCallback(() => {
    console.log("currentPage", currentPage);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxLimit(maxLimit - pageNumberLimit);
      setMinLimit(minLimit - pageNumberLimit);
    }
  }, [currentPage, minLimit, maxLimit, pageNumberLimit]);
  const goToPrevPage = useCallback(() => {
    setCurrentPage(currentPage - 1);
    prevPagesArray();
  }, [currentPage, prevPagesArray]);

  let pageIncementBtn = null;
  if (pagination.length > maxLimit) {
    pageIncementBtn = (
      <Item className="btn btn-tertiary" onClick={goToNextPage}>
        ...
      </Item>
    );
  }

  let pageDecrementBtn = null;

  if (minLimit >= pageNumberLimit && currentPage !== 1) {
    pageDecrementBtn = (
      <Item className="btn btn-tertiary" onClick={goToPrevPage}>
        ...
      </Item>
    );
  }

  const pagesArray = pagination.map((page) => {
    console.log("page < maxLimit + 1 ", page < maxLimit + 1);
    console.log("page > minLimit ", page > minLimit);
    if (page < maxLimit + 1 && page > minLimit) {
      return (
        <Item
          className={`btn btn-tertiary ${currentPage === page ? `active` : ``}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </Item>
      );
    } else {
      return null;
    }
  });

  return {
    currentPage,
    pages,
    goToNextPage,
    goToPrevPage,
    pagesArray,
    firstIndex,
    lastIndex,
    canGoNext,
    canGoPrev,
    pageDecrementBtn,
    pageIncementBtn,
  };
};
