import axios from "axios";
import toast from "react-hot-toast";
import API_URL from "../../api/api.constants";
import { getBase64 } from "../../helpers";
import {
  FETCH_BRANDS_SUCCESS,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_CATEGORIES_SUCCESS,
  FETCH_BRAND_USERS_SUCCESS,
  CREATE_BRAND_SUCCESS,
  EDIT_BRAND_SUCCESS,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_CATEGORY_SUCCESS,
  CREATE_BRAND_CATEGORY_SUCCESS,
  EDIT_BRAND_CATEGORY_SUCCESS,
  FETCH_BRANDS_LOADING,
  FETCH_CLIENT_BRANDS_SUCCESS,
  FETCH_CLIENT_USER_BRANDS_SUCCESS,
  FETCH_BRAND_LOADING,
} from "./actions.constants";

export const fetchBrands = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  dispatch({ type: FETCH_BRANDS_LOADING });
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${API_URL.brands}`, config)

    .then((res) => {
      if (res.status === 200) {
        return dispatch({ type: FETCH_BRANDS_SUCCESS, data: res.data.data });
      }
      return Promise.resolve(res);
    })
    .catch((error) => toast.error(`${error.code}: ${error.message}`));
};

export const fetchBrand = (id) => (dispatch) => {
  dispatch({ type: FETCH_BRAND_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${API_URL.brands}/${id}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: FETCH_BRAND_SUCCESS, data: res.data.data });
      }
      return Promise.resolve(res);
    })
    .catch((error) => toast.error(`${error.code}: ${error.message}`));
};

export const fetchBrandCategories = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brandCategories}`, config)
    .then((res) => {
      return Promise.resolve(res);
    })
    .then(
      ({ data }) =>
        data && dispatch({ type: FETCH_BRAND_CATEGORIES_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchBrandUsers = (userId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brands}/${userId}/users`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_BRAND_USERS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const createBrand =
  (formData, address, lat, lng) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = await getBase64(formData?.logo[0]);

    return axios(API_URL.brands, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        brand_name: formData.name,
        email: formData.email,
        country: formData.countryName.value,
        city: formData.cityName.value,
        telephone: formData.mobileNumber,
        category_id: formData.category.id,
        po_box: formData.poBox,
        address,
        image,
        lat,
        long: lng,
        client_id: formData.clientName.id,
      },
    })
      .then((res) => {
        dispatch({ type: CREATE_BRAND_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const editBrand =
  (formData, brandData, brandId, address, lat, lng) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = formData.logo[0]
      ? await getBase64(formData.logo[0])
      : brandData?.image;
    return axios(`${API_URL.brands}/${brandId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        brand_name: formData.name,
        email: formData.email,
        client_id: formData?.clientName.id,
        country: formData.countryName.value,
        city: formData.cityName.value,
        telephone: formData.mobileNumber,
        po_box: formData.poBox,
        category_id: formData.category.id,
        address: address ? address : brandData?.address,
        lat: lat ? lat : brandData?.lat,
        long: lng ? lng : brandData?.long,
        image: image,
      },
    })
      .then((res) => {
        dispatch({ type: EDIT_BRAND_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const deleteBrand = (brandId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brands}/${brandId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_BRAND_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const createBrandCategory = (formData) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  return axios(API_URL.brandCategories, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      category_name: formData?.categoryName,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_BRAND_CATEGORY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const editBrandCategory = (categoryId, categoryName) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  return axios(`${API_URL.brandCategories}/${categoryId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      category_name: categoryName,
    },
  })
    .then((res) => {
      dispatch({ type: EDIT_BRAND_CATEGORY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const deleteBrandCategory = (brandId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brandCategories}/${brandId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_BRAND_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const fetchClientBrands = (userId) => (dispatch) => {
  dispatch({ type: FETCH_BRANDS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  dispatch({ type: FETCH_BRANDS_LOADING });
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clients}/${userId}/brands`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_CLIENT_BRANDS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchClientUserBrands = (clientId) => (dispatch) => {
  dispatch({ type: FETCH_BRANDS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clients}/${clientId}/brands`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_CLIENT_USER_BRANDS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};
