import React from "react";
import { useSelector } from "react-redux";

import { MainContent, PlansTable } from "@vms-admin/components";

export const Plans = () => {
  const plans = useSelector(({ plans }) => plans);

  return (
    <MainContent>
      <PlansTable
        plans={plans?.plans?.data}
        plansLoading={plans.plansLoading}
      />
    </MainContent>
  );
};

export default Plans;
