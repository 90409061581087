import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import {
  Button,
  TableCard,
  MainContent,
  Modal,
  ToolbarButton,
  Buttons,
  TableCardLoader,
} from "@vms-admin/components";
import {
  active,
  dismissToast,
  formatDate,
  inActive,
  somethignWentWrong,
} from "../../helpers";
import { deleteClient, fetchClients } from "../../redux/actions";

export const ClientManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ id: 0, name: "" });
  const clients = useSelector(({ clients }) => clients);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(
    () =>
      dispatch(fetchClients()).then((res) => {
        if (res.status === 401) {
          localStorage.removeItem("access-token");
          history.push("/");
        }
      }),
    [dispatch, history]
  );

  const handleToggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const handleDeleteClient = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteClient(id))
        .then((res) => {
          if (res.status === 204) {
            dispatch(fetchClients());
            handleToggleModal();
            toast.success("Client Deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, handleToggleModal]
  );

  return (
    <MainContent>
      <TableCard
        title="Client Management"
        btnText="Create New Client"
        btnUrl="/create-client"
        hasLink
      >
        {!clients?.clients?.length && !clients.loadingClients && (
          <tbody>
            <tr>
              <td className="empty_list_message">No CLients found</td>
            </tr>
          </tbody>
        )}
        {clients.loadingClients ? (
          <TableCardLoader />
        ) : (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>Client Name</th>
                <th>Email Address</th>
                <th>Status</th>
                <th>Created at</th>
                <td>Actions</td>
              </tr>
            </thead>

            <tbody>
              {clients?.clients?.map(
                ({ id, client_name, email, confirmed, created_at }, index) => (
                  <tr
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    key={id}
                  >
                    <td>{index + 1}</td>
                    <td>{client_name}</td>
                    <td>{email}</td>
                    <td>
                      {confirmed ? active("Confirmed") : inActive("Pending")}
                    </td>
                    <td>{formatDate(created_at)}</td>
                    <td>
                      <ToolbarButton
                        btns={[
                          { btnUrl: `/client/${id}`, btnText: "View" },
                          { btnUrl: `/edit-client/${id}`, btnText: "Edit" },
                          {
                            handleClick: () => {
                              handleToggleModal();
                              setDeleteItem({ id, name: client_name });
                            },
                            btnText: "Delete",
                          },
                        ]}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </>
        )}
      </TableCard>
      {isModalOpen && (
        <Modal title="Delete">
          Are you sure you want to delete {deleteItem.name}?
          <Buttons>
            <Button variant="secondary" handleClick={handleToggleModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              handleClick={() => handleDeleteClient(deleteItem.id)}
            >
              Confirm
            </Button>
          </Buttons>
        </Modal>
      )}
    </MainContent>
  );
};

export default ClientManagement;
