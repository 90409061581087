import React from "react";
import { Button } from "@vms-admin/components";

export const Pagination = ({
  goToNextPage,
  goToPrevPage,
  pagesArray,
  canGoNext,
  canGoPrev,
  pageDecrementBtn,
  pageIncementBtn,
}) => (
  <div className="kmmrce-pagination">
    <Button
      variant="tertiary"
      handleClick={goToPrevPage}
      disabled={!canGoPrev()}
    >
      Prev
    </Button>
    <div>
      {pageDecrementBtn}
      {pagesArray}
      {pageIncementBtn}
    </div>
    <Button
      variant="tertiary"
      handleClick={goToNextPage}
      disabled={!canGoNext()}
    >
      Next
    </Button>
  </div>
);

export default Pagination;
