import React from "react";
import Skeleton from "react-loading-skeleton";

import { TableCard } from "@vms-admin/components";

const CustomerDetailsLoader = () => (
  <TableCard
    title={
      <Skeleton
        height={25}
        width={300}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    }
  >
    <tbody>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>

      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>

      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={150} width={150} borderRadius="50%" />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <Skeleton height={400} width="100%" borderRadius="12px" />
        </td>
      </tr>
    </tbody>
  </TableCard>
);

export default CustomerDetailsLoader;
