import React from "react";
import CountUp from "react-countup";

export const StatsCard = ({
  title = "Total Orders",
  value = "0",
  prefix = "",
  suffix = "",
  decimals = 2,
  duration = 1.5,
  hasDetails = true,
  ...rest
}) => {
  return (
    <div {...rest} className={`card card_padded ${rest?.className || ""}`}>
      <div className="statbox">
        <h4
          style={{
            fontWeight: "normal",
          }}
        >
          {title}
        </h4>
        <h3
          style={{
            fontWeight: "normal",
          }}
        >
          <CountUp
            className="animate_number_decima"
            end={value}
            prefix={prefix}
            suffix={suffix}
            decimals={decimals}
            duration={duration}
          />
        </h3>
        {hasDetails && (
          <div className="statbox_info show">
            <span className="green_text block">72.5%</span>
            <span className="grey_text block">Since Last Month </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatsCard;
