import React from "react";

export const INControl = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 205 205"
    style={{ enableBackground: "new 0 0 205 205;" }}
    xmlSpace="preserve"
    width="35px"
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="0.6374"
      y1="102.3673"
      x2="204.6374"
      y2="102.3673"
    >
      <stop offset="0" style={{ stopColor: "#027FFF" }} />
      <stop offset="0.12" style={{ stopColor: "#0692F9" }} />
      <stop offset="0.38" style={{ stopColor: "#0EB7EE" }} />
      <stop offset="0.62" style={{ stopColor: "#14D2E6" }} />
      <stop offset="0.83" style={{ stopColor: "#18E2E2" }} />
      <stop offset="1" style={{ stopColor: "#19E8E0" }} />
    </linearGradient>
    <path
      fill="url(#SVGID_1_)"
      d="M16.6,0C7.8,0,0.6,7.2,0.6,16v31.8c0,8.8,7.2,16,16,16.1H56c2.1,1.3,3,3.8,2.3,6.1l-52.9,53l0,0
	c-3.1,3-4.8,7.1-4.8,11.4v54.3c0,8.9,7.2,16,16,16.1h31.6c8.9,0,16-7.2,16-16.1v-114c0.9-5.7,5.5-10.1,11.2-10.8h31.9
	c18.4,0.1,33.4,15,33.5,33.4v81.3l0,0v10.1c0,8.9,7.2,16,16,16.1h31.7c8.9,0,16-7.2,16-16.1V97.3c0-52.5-34.8-89.6-68.2-93.1
	c0,0-11-1.4-16.5,3.9L97.5,30.6l-0.2,0.2L75.7,52.5l-5.4,5.4c-2.5,0.5-5.1-0.9-6-3.3V16c0-8.9-7.2-16-16-16.1H16.6z"
    />
  </svg>
);
