import React from "react";

export const HidePassword = () => (
  <svg
    className="state2"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18.385"
    viewBox="0 0 24 18.385"
  >
    <g
      id="Group_2669"
      data-name="Group 2669"
      transform="translate(-1875 -132.807)"
    >
      <path
        id="iconmonstr-eye-5"
        d="M12.015,7c4.751,0,8.063,3.012,9.5,4.636-1.4,1.837-4.713,5.364-9.5,5.364-4.42,0-7.93-3.536-9.478-5.407C4.03,9.946,7.354,7,12.015,7Zm0-2C4.446,5,0,11.551,0,11.551S4.835,19,12.015,19C19.748,19,24,11.551,24,11.551S19.709,5,12.015,5ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Z"
        transform="translate(1875 130)"
        fill="#333"
      ></path>
      <g
        id="Rectangle_599"
        data-name="Rectangle 599"
        transform="translate(1893.364 134.222) rotate(45)"
        fill="#333"
        stroke="#fff"
        strokeWidth="1"
      >
        <rect width="2" height="20" stroke="none"></rect>
        <rect x="-0.5" y="-0.5" width="3" height="21" fill="none"></rect>
      </g>
    </g>
  </svg>
);
