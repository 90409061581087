import React from "react";

export const Customer = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_19935"
      data-name="Group 19935"
      transform="translate(20194 4296)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(-20194 -4296)"
        fill="rgba(255,255,255,0)"
      />
      <g id="noun_User_3073421" transform="translate(-20197 -4299)">
        <g id="Group_20408" data-name="Group 20408" transform="translate(8 6)">
          <path
            id="Path_25578"
            data-name="Path 25578"
            d="M16.906,17.45H14.094C10.73,17.455,8.005,19.217,8,22.625v1.9A.472.472,0,0,0,8.469,25H22.531A.472.472,0,0,0,23,24.525v-1.9C22.995,19.217,20.27,17.455,16.906,17.45Zm5.156,6.6H8.937V22.625c.005-2.884,2.311-4.22,5.156-4.225h2.813c2.846.005,5.151,1.341,5.156,4.225ZM15.5,15.5a4.75,4.75,0,1,0-4.687-4.75A4.719,4.719,0,0,0,15.5,15.5Zm0-8.55a3.8,3.8,0,1,1-3.75,3.8A3.775,3.775,0,0,1,15.5,6.95Z"
            transform="translate(-8 -6)"
            fill="#333"
            stroke="#333"
            strokeWidth="0.2"
          />
        </g>
      </g>
    </g>
  </svg>
);
