import styled from "styled-components";

import {
  Title as FormTitle,
  Icon as FormIcon,
  Wrapper as FormWrapper,
  Text as FormText,
  Form as FormInherited,
  Link as FormLink,
  LinkText as FormLinkText,
} from "../../pages/confirmation/confirmation.style";

export const Wrapper = styled(FormWrapper)``;
export const Icon = styled(FormIcon)``;
export const Title = styled(FormTitle)``;
export const Text = styled(FormText)``;
export const Form = styled(FormInherited)``;
export const Link = styled(FormLink)``;
export const LinkText = styled(FormLinkText)``;
