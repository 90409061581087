import React from "react";

export const Social = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_20857"
      data-name="Group 20857"
      transform="translate(-1663 -590)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(1663 590)"
        fill="rgba(255,255,255,0)"
      />
      <g id="noun-people-3095698" transform="translate(1664.882 551.725)">
        <path
          id="Path_25688"
          data-name="Path 25688"
          d="M82.91,81.188a3.853,3.853,0,1,1-3.77,3.852A3.8,3.8,0,0,1,82.91,81.188Zm0,1.024a2.829,2.829,0,1,0,2.745,2.827A2.782,2.782,0,0,0,82.91,82.212Z"
          transform="translate(-76.764 -37.057)"
          fill="#333"
          stroke="#333"
          strokeWidth="0.2"
        />
        <path
          id="Path_25689"
          data-name="Path 25689"
          d="M0,385.971l.164-.533c.574-2.049,1.8-4.057,4.139-4.057H7.908c2.377,0,3.606,2.008,4.18,4.057l.164.533H11.145l-.041-.246c-.451-1.557-1.352-3.278-3.2-3.278H4.3c-1.844,0-2.7,1.721-3.155,3.278l-.082.246H0Z"
          transform="translate(0 -328.236)"
          fill="#333"
          stroke="#333"
          strokeWidth="0.2"
        />
        <path
          id="Path_25690"
          data-name="Path 25690"
          d="M373.55,42.984a3.853,3.853,0,1,1-3.77,3.852A3.8,3.8,0,0,1,373.55,42.984Zm0,1.024a2.829,2.829,0,1,0,2.745,2.827A2.782,2.782,0,0,0,373.55,44.008Z"
          transform="translate(-358.676)"
          fill="#333"
          stroke="#333"
          strokeWidth="0.2"
        />
        <path
          id="Path_25691"
          data-name="Path 25691"
          d="M353.41,344.163a3.335,3.335,0,0,1,2.418-.983h3.606c2.377,0,3.606,2.049,4.18,4.057l.2.7h-1.106l-.082-.41c-.451-1.516-1.352-3.278-3.2-3.278h-3.606a2.525,2.525,0,0,0-1.639.615,4.13,4.13,0,0,0-.779-.7Z"
          transform="translate(-342.798 -291.181)"
          fill="#333"
          stroke="#333"
          strokeWidth="0.2"
        />
      </g>
    </g>
  </svg>
);
