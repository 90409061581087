import axios from "axios";

import API_URL from "../../api/api.constants";
import {
  FETCH_SUBSCRIPTIONS_LOADING,
  FETCH_SUBSCRIPTIONS_SUCCESS,
} from "./actions.constants";

export const fetchSubscriptions = (page) => (dispatch) => {
  dispatch({ type: FETCH_SUBSCRIPTIONS_LOADING });

  return axios
    .get(`${API_URL.subscriptions}?page=${page}&per_page=50`)
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        dispatch({ type: FETCH_SUBSCRIPTIONS_SUCCESS, data: res.data.data });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};
