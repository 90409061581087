import { FETCH_ALL_USERS_SUCCESS } from "../actions/actions.constants";

const initialState = {
  allUsers: [],
};

const allUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS_SUCCESS:
      return { ...state, allUsers: action };
    default:
      return state;
  }
};
export default allUsersReducer;
