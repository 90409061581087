import React from "react";

export const Upload = ({ width = 80, height = 80 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24.545"
    viewBox="0 0 18 24.545"
  >
    <g id="noun-upload-1713303" transform="translate(-226.801 -112)">
      <path
        id="Path_25673"
        data-name="Path 25673"
        d="M251.372,112a.952.952,0,0,0-.563.231l-6.955,6.545a.82.82,0,0,0-.032,1.157.843.843,0,0,0,1.157.032l5.574-5.254v17.744a.818.818,0,1,0,1.636,0V114.711l5.574,5.254a.856.856,0,0,0,1.157-.032.819.819,0,0,0-.032-1.157l-6.954-6.545a.727.727,0,0,0-.562-.231Z"
        transform="translate(-15.571 0)"
        fill="#3cd17f"
      />
      <path
        id="Path_25674"
        data-name="Path 25674"
        d="M227.619,425.6a.818.818,0,1,0,0,1.636h16.364a.818.818,0,1,0,0-1.636Z"
        transform="translate(0 -290.691)"
        fill="#3cd17f"
      />
    </g>
  </svg>
);
