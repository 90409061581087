import React from "react";

export const LogoFull = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="196"
    height="40"
    viewBox="0 0 184 33"
  >
    <path
      d="M63.41,150.16l-9.14-10.53v10.53h-3.6V128.61h3.6V138l8.46-9.39h4.59L58,138.8l10.12,11.36Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M69.32,128.61h4.34l7.17,16.25,7.39-16.25h3.69v21.55h-3.6V135.69l-6.43,14.47H79.23l-6.31-14.47v14.47h-3.6Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M95.54,128.61h4.34l7.17,16.25,7.39-16.25h3.69v21.55h-3.6V135.69l-6.43,14.47h-2.65l-6.31-14.47v14.47h-3.6Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M133.89,150.16l-5.35-8.65h-3.17v8.65h-3.61V128.61h8.1a6.51,6.51,0,0,1,6.62,6.41,6.08,6.08,0,0,1-4.1,6.09l5.88,9Zm-8.52-12.1h4.37a3,3,0,0,0,3.1-3,2.93,2.93,0,0,0-3.1-2.93h-4.37Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M138.44,139.36a10.73,10.73,0,0,1,10.84-11.05c4.95,0,9.32,3.2,10,7.63h-4a6.09,6.09,0,0,0-6-4.12,7.2,7.2,0,0,0-7.11,7.54c0,4.3,3.08,7.6,7.11,7.6a6.06,6.06,0,0,0,6.06-4.4h4c-.86,4.67-5,7.91-10.06,7.91A10.76,10.76,0,0,1,138.44,139.36Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M175,128.61v3.45h-9.11v5.08H174v3.45h-8.12v6.12H175v3.45H162.27V128.61Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M179.68,148.48a1.73,1.73,0,1,1-1.74-1.73A1.74,1.74,0,0,1,179.68,148.48Zm-.28,0a1.45,1.45,0,1,0-1.45,1.46A1.46,1.46,0,0,0,179.4,148.48Zm-1.08.15.49.77h-.5l-.42-.7h-.18v.7h-.43v-1.87H178a.6.6,0,0,1,.6.59A.54.54,0,0,1,178.32,148.63Zm-.61-.33H178a.18.18,0,0,0,.19-.18.18.18,0,0,0-.19-.19h-.3Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M198.9,135.2a6.57,6.57,0,0,1-6.89,6.65h-4.65v8.31h-3.6V128.61H192A6.56,6.56,0,0,1,198.9,135.2Zm-3.63,0a3.12,3.12,0,0,0-3.26-3.14h-4.65v6.34H192A3.15,3.15,0,0,0,195.27,135.2Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M214.85,135.11v15h-3.42v-2a5.77,5.77,0,0,1-4.8,2.34c-4.06,0-7.23-3.45-7.23-7.85s3.17-7.82,7.23-7.82a5.77,5.77,0,0,1,4.8,2.34v-2ZM211.43,145v-4.62a4.78,4.78,0,0,0-4.09-2.28,4.57,4.57,0,0,0,0,9.14A4.86,4.86,0,0,0,211.43,145Z"
      transform="translate(-50.67 -128.31)"
    />
    <path
      d="M232.17,135.11l-8.49,20.34H220l2.7-6.09-6-14.25h3.7l4,10.25,4-10.25Z"
      transform="translate(-50.67 -128.31)"
    />
  </svg>
);
