import React from "react";

export const KMMRCELoading = ({ width = 70, height = 70 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="59.996"
    viewBox="0 0 60 59.996"
  >
    <path
      id="Path_25680"
      data-name="Path 25680"
      d="M28.132,60H31.8V49.867H28.132Zm5.694-10.416L36.674,59.3,40.2,58.265,37.35,48.546ZM19.742,58.25l3.523,1.035,2.847-9.719-3.523-1.035ZM39.2,47.7l5.476,8.522,3.089-1.986-5.476-8.521ZM12.186,54.2l3.089,1.986,5.476-8.522-3.089-1.986ZM43.8,44.363,51.455,51l2.4-2.776L46.2,41.587ZM6.1,48.207l2.4,2.776,7.657-6.635-2.4-2.776Zm41.223-8.258,9.215,4.208,1.525-3.34L48.853,36.61Zm-45.418.82,1.526,3.34L12.65,39.9l-1.526-3.34Zm47.534-6.1,10.026,1.442.523-3.634L49.965,31.038ZM0,32.427l.522,3.634,10.026-1.442-.522-3.634Zm49.452-7.048.522,3.634L60,27.572l-.522-3.634ZM.009,27.519l10.026,1.442.522-3.634L.531,23.884ZM47.352,20.1l1.525,3.34,9.215-4.208-1.525-3.34ZM1.933,19.179l9.215,4.208,1.525-3.34L3.459,15.84Zm41.9-3.53,2.406,2.776L53.9,11.79l-2.4-2.776ZM6.143,11.776,13.8,18.411l2.4-2.776L8.547,9Zm33.106.558,3.089,1.984L47.814,5.8,44.725,3.812ZM12.234,5.765l5.476,8.521L20.8,12.3,15.323,3.78ZM33.88,10.436,37.4,11.471l2.847-9.719L36.727.718ZM19.8,1.731l2.847,9.718,3.523-1.035L23.327.7Zm8.4,8.4h3.672V0H28.2Z"
      transform="translate(0 -0.001)"
      fill="#4b42ad"
    />
  </svg>
);
