import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchMerchant } from "../../redux/actions";
import {
  MainContent,
  TableCard,
  Map,
  UsersTable,
  BrandsTable,
} from "@vms-admin/components";
import MerchantDetailsLoader from "./merchant-detials-loader";

export const MerchantDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const merchant = useSelector(({ merchant }) => merchant.merchant);
  const merchantLoading = useSelector(
    ({ merchant }) => merchant.merchantLoading
  );

  useEffect(() => dispatch(fetchMerchant(id)), [dispatch, id]);

  return (
    <MainContent>
      {merchantLoading ? (
        <MerchantDetailsLoader />
      ) : (
        <div className="cards_wrapper">
          <TableCard title={`${merchant?.merchant_name} Details`}>
            <tbody>
              <tr>
                <td>
                  <strong>Merchant UID</strong>
                </td>
                <td>{merchant?.merchant_uid}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant Name</strong>
                </td>
                <td>{merchant?.merchant_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant Email</strong>
                </td>
                <td>{merchant?.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant Category</strong>
                </td>
                <td>{merchant?.merchant_category?.category_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Name</strong>
                </td>
                <td>{merchant?.client?.client_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand Name</strong>
                </td>
                <td>{merchant?.brand?.brand_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Attributes</strong>
                </td>
                <td>
                  {merchant?.attributes?.map(({ attribute_name }) => (
                    <span className="label label-info">{attribute_name}</span>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Amenities</strong>
                </td>
                <td>
                  {merchant?.amenities?.map(({ amenity_name }) => (
                    <span className="label label-info">{amenity_name}</span>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Confirmed</strong>
                </td>
                <td>
                  {merchant?.confirmed ? (
                    <span className="label label-create">True</span>
                  ) : (
                    <span className="label label-delete">False</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant P.O Box</strong>
                </td>
                <td>{merchant?.po_box}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant Telephone</strong>
                </td>
                <td>{merchant?.telephone}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant Country</strong>
                </td>
                <td>{merchant?.country}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant city</strong>
                </td>
                <td>{merchant?.city}</td>
              </tr>
              <tr>
                <td>
                  <strong>Merchant Image</strong>
                </td>
                <td>
                  <img
                    src={merchant?.image}
                    alt={merchant?.merchant_name}
                    width="150"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Map
                    userLat={parseInt(merchant?.lat)}
                    userLng={parseInt(merchant?.long)}
                    noAddressField
                  />
                </td>
              </tr>
            </tbody>
          </TableCard>
          {merchant?.merchant_users && (
            <UsersTable users={merchant?.merchant_users} hasActions={false} />
          )}

          {merchant?.brands && (
            <BrandsTable brands={merchant?.brands} hasActions={false} />
          )}
        </div>
      )}
    </MainContent>
  );
};

export default MerchantDetails;
