import {
  FETCH_ACTIVITY_LOGS_SUCCESS,
  FETCH_ACTIVITY_LOG_SUCCESS,
} from "../actions/actions.constants";

const initialState = {
  activityLogs: [],
  activityLog: {},
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_LOGS_SUCCESS:
      return { ...state, activityLogs: action };
    case FETCH_ACTIVITY_LOG_SUCCESS:
      return { ...state, activityLog: action.data };
    default:
      return state;
  }
};
export default adminReducer;
