import React, { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";

import { Button } from "@vms-admin/components";
import { useClickOutside } from "../../hooks/useClickOutside";

export const ToolbarButton = ({ btns }) => {
  const [toggleMenu, setToogleMenu] = useState(false);
  const targetEl = useRef();
  const handleToggleMenu = useCallback(
    () => setToogleMenu(!toggleMenu),
    [toggleMenu]
  );
  useClickOutside(targetEl, () => setToogleMenu(false));

  return (
    <div className="btn-toolbar" ref={targetEl}>
      <span className="btn-toolbar-trigger" onClick={handleToggleMenu}></span>
      {toggleMenu && (
        <ul>
          {btns.map(({ btnUrl, btnText, handleClick, show = true }) => (
            <li>
              {btnUrl ? (
                <>{show && <Link to={btnUrl}>{btnText}</Link>}</>
              ) : (
                <>
                  {show && (
                    <Button variant="link" onClick={handleClick}>
                      {btnText}
                    </Button>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ToolbarButton;
