import * as Page from "./pages";

export const protectedRoutes = [
  {
    path: "/subscriptions",
    component: Page.Subscriptions,
  },
  { path: "/subscription-plans", component: Page.Plans },
  { path: "/customers", component: Page.Customers },
  { path: "/otp", component: Page.Otp },
  { path: "/profile", component: Page.Profile },
  { path: "/account-settings", component: Page.AccountSettings },
  {
    path: "/subscription-payment",
    component: Page.Payment,
  },
  {
    path: "/voucher-redemption",
    component: Page.VoucherRedemptions,
  },
  { path: "/create-plan", component: Page.CreatePlan },
  { path: "/create-user", component: Page.CreateUser },
  { path: "/edit-user/:id", component: Page.EditUser },
  { path: "/edit-plan/:id", component: Page.EditPlan },
  {
    path: "/edit-merchant/:id",
    component: Page.EditMerchantForm,
  },
  {
    path: "/create-merchant",
    component: Page.CreateMerchantForm,
  },
  { path: "/edit-brand/:id", component: Page.EditBrand },
  { path: "/create-brand", component: Page.CreateBrand },
  {
    path: "/edit-client/:id",
    component: Page.EditClient,
  },
  { path: "/vouchers", component: Page.Vouchers },
  {
    path: "/change-password",
    component: Page.ChangePassword,
  },
  {
    path: "/dashboard-operational",
    component: Page.DashboardContent,
  },
  {
    path: "/dashboard-revenue",
    component: Page.DashboardRevenue,
  },
  {
    path: "/client-management",
    component: Page.ClientManagement,
  },
  {
    path: "/brand-management",
    component: Page.BrandManagement,
  },
  {
    path: "/merchant-management",
    component: Page.MerchantManagement,
  },
  {
    path: "/admin-user-management",
    component: Page.UserManagement,
  },
  {
    path: "/admin-countries",
    component: Page.CountryManagement,
  },
  {
    path: "/admin-merchant-settings",
    component: Page.MerchantSettings,
  },
  {
    path: "/admin-client-settings",
    component: Page.ClientSettings,
  },
  {
    path: "/admin-voucher-settings",
    component: Page.VoucherSettings,
  },
  { path: "/client-overview", component: Page.Overview },
  { path: "/brand-overview", component: Page.Overview },
  {
    path: "/merchant-overview",
    component: Page.Overview,
  },
  {
    path: "/create-voucher",
    component: Page.CreateVoucherForm,
  },

  {
    path: "/admin-brand-settings",
    component: Page.BrandSettings,
  },
  {
    path: "/create-client",
    component: Page.CreateClient,
  },
  {
    path: "/client/:id",
    component: Page.ClientDetails,
  },
  {
    path: "/brand/:id",
    component: Page.BrandDetails,
  },
  {
    path: "/merchant/:id",
    component: Page.MerchantDetails,
  },
  {
    path: "/edit-voucher/:id",
    component: Page.EditVoucher,
  },
  {
    path: "/plan/:id",
    component: Page.PlanDetails,
  },
  {
    path: "/whatsNew/:id",
    component: Page.WhatsNew,
  },
  {
    path: "/user/:id",
    component: Page.UserDetails,
  },
  {
    path: "/admin-activity-logs",
    component: Page.ActivityLogs,
  },
  {
    path: "/admin-activity-logs/:id",
    component: Page.ActivityLogDetails,
  },
  { path: "/voucher/:id", component: Page.VoucherDetails },
  { path: "/customer/:id", component: Page.CustomerDetails },
];

export const routes = [
  { path: "/", component: Page.Login },
  { path: "/register", component: Page.Register },
  { path: "/resend-confirmation", component: Page.ResendConfirmation },
  { path: "/confirm-email/:token", component: Page.ConfirmationSuccessful },
  { path: "/email-sent", component: Page.EmailSent },
];
