import React from "react";
import Skeleton from "react-loading-skeleton";

import { TableCard } from "@vms-admin/components";

const PlanDetailsLoader = () => (
  <TableCard
    title={
      <Skeleton
        height={25}
        width={300}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    }
  >
    <tbody>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={15} width={100} />
        </td>
        <td>
          <Skeleton height={15} width={200} />
        </td>
      </tr>
    </tbody>
  </TableCard>
);

export default PlanDetailsLoader;
