import styled from "styled-components";

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => (props.noMargin ? 0 : "16px")};

  * {
    margin-left: ${(props) => props.theme.space[3]}px;
  }
`;
