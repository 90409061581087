import React from "react";

export const Dashboard = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_19931"
      data-name="Group 19931"
      transform="translate(20194 4402)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(-20194 -4402)"
        fill="rgba(255,255,255,0)"
      />
      <g id="noun_Graph_1658610" transform="translate(-20229.305 -4438.67)">
        <path
          id="Path_25575"
          data-name="Path 25575"
          d="M50.085,53.5l-5.612-5.721-5.051,5.149L38.3,51.784l6.173-6.293,5.612,5.721,4.489-4.577H50.886V45H57.3v6.538H55.7v-3.76Z"
          transform="translate(0 0)"
          fill="#333"
        />
      </g>
    </g>
  </svg>
);
