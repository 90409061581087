import React from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

export const InputRangeField = ({
  step,
  minValue,
  maxValue,
  label,
  name,
  handleChange,
  value,
  ...rest
}) => {
  return (
    <div className="input_holder" {...rest}>
      <label style={{ marginBottom: "24px", display: "block" }}>{label}</label>
      <InputRange
        step={step}
        maxValue={maxValue}
        minValue={minValue}
        value={value}
        name={name}
        onChange={handleChange}
        formatLabel={(value) => (value < 10 ? `0${value}:00` : `${value}:00`)}
      />
    </div>
  );
};

export default InputRangeField;
