import React from "react";

export const Chat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.219"
    height="16.337"
    viewBox="0 0 18.219 16.337"
  >
    <path
      id="Path_25626"
      data-name="Path 25626"
      d="M27.54,15.78H14a2.241,2.241,0,0,0-2.238,2.238v8.131A2.241,2.241,0,0,0,14,28.387h3.958l5.209,3.466a.353.353,0,0,0,.549-.294V28.387H27.54a2.241,2.241,0,0,0,2.238-2.238V18.018A2.241,2.241,0,0,0,27.54,15.78Zm1.531,10.366a1.534,1.534,0,0,1-1.531,1.531h-4.18a.353.353,0,0,0-.353.353v2.86L18.26,27.739a.353.353,0,0,0-.2-.059H14a1.534,1.534,0,0,1-1.531-1.531V18.018A1.534,1.534,0,0,1,14,16.487H27.54a1.534,1.534,0,0,1,1.531,1.531Z"
      transform="translate(-11.66 -15.68)"
      fill="#8e8e8e"
      stroke="#8e8e8e"
      strokeWidth="0.2"
    />
  </svg>
);
