import React from "react";
import Skeleton from "react-loading-skeleton";

import { Card, Grid } from "@vms-admin/components";

const EditBrandLoader = () => (
  <div className="cards_wrapper">
    <Card title={<Skeleton width={150} height={22} />}>
      <Grid columns={3}>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
      </Grid>
      <Grid columns={2}>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
      </Grid>
      <div>
        <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
        <Skeleton width="100%" height={148} style={{ marginBottom: 16 }} />
        <Skeleton
          width="100%"
          height={52}
          style={{ marginBottom: 20 }}
          borderRadius={7}
        />
      </div>
    </Card>
    <Card title={<Skeleton width={150} height={22} />}>
      <Grid columns={3}>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
      </Grid>
      <Skeleton width="100%" height={40} style={{ marginBottom: 10 }} />
      <Skeleton width="100%" height={400} borderRadius={7} />
    </Card>
  </div>
);

export default EditBrandLoader;
