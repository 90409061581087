import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import clientsReducer from "./clients.reducer";
import countriesReducer from "./countries.reducer";
import plansReducer from "./plans.reducer";
import merchantReducer from "./merchant.reducer";
import brandsReducer from "./brands.reducer";
import voucherReducer from "./voucher.reducer";
import sidebarReducer from "./sidebar.reducer";
import customerReducer from "./customer.reducer";
import subscriptionsReducer from "./subscriptions.reducer";
import adminReducer from "./admin.reducers";
import allUsersReducer from "./allusers.reducers";
import dashboardReducer from "./dashboard.reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  countryManagement: countriesReducer,
  clients: clientsReducer,
  plans: plansReducer,
  merchant: merchantReducer,
  brands: brandsReducer,
  voucher: voucherReducer,
  sidebar: sidebarReducer,
  customer: customerReducer,
  subscriptions: subscriptionsReducer,
  admin: adminReducer,
  allUsers: allUsersReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
