import React from "react";

export const Calendar = ({ width = 18, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.396"
    height="15.679"
    viewBox="0 0 17.396 15.679"
  >
    <g id="Group" transform="translate(0.2 0.2)">
      <path
        id="Compound_Path"
        data-name="Compound Path"
        d="M28.2,19.133H24.276V17.6H23.4v1.533H16V17.6h-.876v1.533H11.2V32.879h17ZM27.32,32H12.076V20.009h3.044v2.423H16V20.009h7.4v2.423h.876V20.009H27.32Z"
        transform="translate(-11.2 -17.6)"
        fill="#8e8e8e"
        stroke="#8e8e8e"
        strokeWidth="0.4"
      />
    </g>
  </svg>
);
