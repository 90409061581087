import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import Select from "react-select";
import { useInView } from "react-intersection-observer";

import { planSchema } from "../../schema";
import {
  Input,
  Button,
  Grid,
  UploadField,
  Textarea,
  ErrorMessage,
  Card,
  MainContent,
  Buttons,
  CustomDatePicker,
} from "@vms-admin/components";
import {
  editPlan,
  fetchCountries,
  fetchCountryInfo,
  fetchCurrencies,
  fetchPlan,
} from "../../redux/actions";
import { getCountryOptions, getCityOptions } from "@vms-admin/utils";
import { getCurrencyOptions } from "../../utils";
import { dismissToast, somethignWentWrong } from "../../helpers";

export const EditPlan = () => {
  const plan = useSelector(({ plans }) => plans.plan);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { ref, inView } = useInView();

  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );
  useEffect(() => {
    plan?.start_date && setStartDate(new Date(plan.start_date));
    plan?.end_date && setEndDate(new Date(plan.end_date));
  }, [plan?.start_date, plan?.end_date, plan]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(planSchema),
  });

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
      setValue("currencyName", "");
    },
    [setValue, dispatch]
  );

  const handleEditPlan = useCallback(
    (data) => {
      dismissToast();
      dispatch(editPlan(data, plan, id, startDate, endDate))
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => history.push("/subscription-plans"), [2000]);
            toast.success(`${data.planName} has been created successfully`);
          } else if (res.status === 409) {
            toast.error("Subscription already exist");
          } else if (res.status === 401) {
            history.push("/");
          } else {
            somethignWentWrong();
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, history, plan, startDate, endDate, id]
  );

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCountryInfo(plan?.country?.id));
  }, [dispatch, plan?.country]);

  useEffect(() => {
    dispatch(fetchPlan(id));
  }, [dispatch, id]);

  useEffect(() => {
    setValue("planName", plan?.plan_name);
    setValue("planDesc", plan?.plan_description);
    setValue("planFeatures", plan?.plan_features);
    setValue("maxMembers", plan?.max_members);
    setValue("perDay", plan?.plan_price_per_day);
    setValue("perMonth", plan?.plan_price_per_month);
    setValue("perYear", plan?.plan_price_per_year);
    setValue("countryName", {
      value: plan?.country,
      label: plan?.country,
    });
    setValue("cityName", {
      value: plan?.city,
      label: plan?.city,
    });
    setValue("currencyName", {
      value: plan?.currency_code,
      label: plan?.currency_code,
    });
    plan?.start_date && setValue("startDate", new Date(plan.start_date));
    plan?.end_date && setValue("endDate", new Date(plan.end_date));
  }, [plan, setValue]);

  useEffect(
    () =>
      !plan &&
      toast.error(
        "It looks like there is an issue, please try again in few minutes "
      ),
    [plan]
  );

  return (
    <MainContent>
      <form onSubmit={handleSubmit(handleEditPlan)} noValidate>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Subscription Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Link to="/subscription-plans" className="btn btn-secondary">
                  Back
                </Link>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        <Card title="Edit Subscription Plan">
          <Input
            label="Plan Name"
            type="text"
            placeholder="Plan Name"
            name="planName"
            register={register}
            errors={errors}
          />
          <Textarea
            label="Plan Description"
            type="text"
            placeholder="Plan Description"
            name="planDesc"
            register={register}
            errors={errors}
          />
          <Input
            label="Plan Features"
            type="text"
            placeholder="Plan Features"
            name="planFeatures"
            register={register}
            errors={errors}
          />
          <UploadField
            label="Plan Image"
            name="image"
            image={plan?.image}
            errors={errors}
            register={register}
          />
          <Grid columns={4}>
            <Input
              type="number"
              name="maxMembers"
              placeholder="20"
              register={register}
              errors={errors}
              label="Max members"
            />
            <Input
              type="number"
              name="perDay"
              placeholder="3.99"
              register={register}
              errors={errors}
              label="Plan price per day"
            />
            <Input
              type="number"
              name="perMonth"
              placeholder="23.99"
              register={register}
              errors={errors}
              label="Plan price per month"
            />
            <Input
              type="number"
              name="perYear"
              placeholder="130.99"
              register={register}
              errors={errors}
              label="Plan price per year"
            />
          </Grid>
          <Grid columns={3}>
            <div className="input_holder">
              <label>Country Name</label>
              <Controller
                name="countryName"
                errors={errors}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    menuPortalTarget={document.body}
                    options={getCountryOptions(
                      countryManagementState.countries
                    )}
                    placeholder="Select Country"
                    className="kmmrce-select-container"
                    classNamePrefix="kmmrce-select"
                    onChange={(e) => {
                      onChange(e);
                      handleChange(e);
                    }}
                    value={value ? value : ""}
                  />
                )}
              />
              {errors && errors["countryName"] && (
                <ErrorMessage text="Country is Required" />
              )}
            </div>
            <div className="input_holder">
              <label>City Name</label>
              <Controller
                name="cityName"
                control={control}
                render={({ field }) => (
                  <Select
                    menuPortalTarget={document.body}
                    {...field}
                    options={getCityOptions(
                      countryManagementState?.countryInfo?.cities
                    )}
                    isLoading={countryManagementState?.countryInfoLoading}
                    loadingMessage={() => "Loading..."}
                    placeholder="Select City"
                    className="kmmrce-select-container"
                    classNamePrefix="kmmrce-select"
                  />
                )}
              />
              {errors && errors["cityName"] && (
                <ErrorMessage text="City is Required" />
              )}
            </div>
            <div className="input_holder">
              <label>Currency</label>
              <Controller
                name="currencyName"
                control={control}
                render={({ field }) => (
                  <Select
                    menuPortalTarget={document.body}
                    {...field}
                    options={getCurrencyOptions(
                      countryManagementState?.countryInfo?.currencies
                    )}
                    placeholder="Select Currency"
                    className="kmmrce-select-container"
                    classNamePrefix="kmmrce-select"
                  />
                )}
              />
              {errors && errors["currencyName"] && (
                <ErrorMessage text="Currency is Required" />
              )}
            </div>
          </Grid>

          <Grid columns={2}>
            <div>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={
                      <CustomDatePicker label={<label>Start at</label>} />
                    }
                  />
                )}
              />
              {errors && errors["startDate"] && (
                <ErrorMessage text="Start Date is Required" />
              )}
            </div>
            <div>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    customInput={
                      <CustomDatePicker label={<label>End at</label>} />
                    }
                  />
                )}
              />
              {errors && errors["endDate"] && (
                <ErrorMessage text="End Date is Required" />
              )}
            </div>
          </Grid>
        </Card>
      </form>
    </MainContent>
  );
};

export default EditPlan;
