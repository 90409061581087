import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import {
  Categories,
  Grid,
  TableCard,
  MainContent,
  Modal,
  Input,
  Button,
  Buttons,
} from "@vms-admin/components";
import {
  createVoucherCategory,
  createVoucherScope,
  deleteVoucherCategory,
  editVoucherCategory,
  fetchVoucherCategories,
  fetchVoucherScopes,
  deleteVoucherScope,
  editVoucherScope,
  fetchVoucherScope,
} from "../../redux/actions";
import { dismissToast, somethignWentWrong } from "../../helpers";

export const VoucherSettings = () => {
  const [isVoucherEditModalOpen, setVoucherEditIsModalOpen] = useState(false);
  const [isVoucherCreateOpen, setIsVoucherCreateOpen] = useState(false);
  const [isVoucherScopeCreateOpen, setIsVoucherScopeCreateOpen] =
    useState(false);
  const [isVoucherScopeEditOpen, setIsVoucherScopeEditOpen] = useState(false);
  const [scopeId, setScopeId] = useState(null);
  const voucher = useSelector(({ voucher }) => voucher);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
  });

  const voucherDetails = useSelector(({ voucher }) => voucher.voucherScope);
  console.log("voucherDetails", voucherDetails);

  const handleVoucherModal = useCallback(
    () => setIsVoucherCreateOpen(!isVoucherCreateOpen),
    [isVoucherCreateOpen]
  );

  const handleVoucherEditModal = useCallback(
    () => setVoucherEditIsModalOpen(!isVoucherEditModalOpen),
    [isVoucherEditModalOpen]
  );

  // const getFilteredVoucherScopes = useCallback(() => {
  //   if (!watch("search")) {
  //     return voucher.scopes;
  //   } else {
  //     return voucher.scopes.filter(({ amenity_name }) =>
  //       amenity_name.toLowerCase().includes(watch("search").toLowerCase())
  //     );
  //   }
  // }, [watch, voucher.scopes]);

  const handleCreateVoucherCategory = useCallback(
    (data) => {
      dismissToast();
      dispatch(createVoucherCategory(data))
        .then((res) => {
          if (res.status === 200) {
            setIsVoucherCreateOpen(false);
            dispatch(fetchVoucherCategories());
            toast.success(`${data.categoryName} has been created Successfully`);
          } else {
            setIsVoucherCreateOpen(true);
            toast.error("Category Name already exists");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleEditVoucherCategory = useCallback(
    (id, categoryName, categoryIcon) => {
      dismissToast();
      dispatch(editVoucherCategory(id, categoryName, categoryIcon))
        .then((res) => {
          if (res.status === 500) {
            setVoucherEditIsModalOpen(true);
            toast.error("Voucher Category already exists");
          } else {
            setVoucherEditIsModalOpen(false);
            dispatch(fetchVoucherCategories());
            toast.success(`${categoryName} has been updated Successfully`);
          }
        })
        .catch((error) => console.log("error", error));
    },
    [dispatch]
  );

  const handleDeleteVoucherCategory = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteVoucherCategory(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchVoucherCategories());
            toast.success("Voucher Category has been deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleCreateVoucherScope = useCallback(
    (data) =>
      dispatch(createVoucherScope(data))
        .then((res) => {
          if (res.status === 200) {
            setIsVoucherScopeCreateOpen(false);
            dispatch(fetchVoucherScopes());
            toast.success(`${data.scopeName} has been created Successfully`);
          } else {
            setIsVoucherScopeCreateOpen(true);
            toast.error("Scope Name already exists");
          }
        })
        .catch(() => somethignWentWrong()),
    [dispatch]
  );
  const handleToggleCreateScopeModal = useCallback(() => {
    setIsVoucherScopeCreateOpen(!isVoucherScopeCreateOpen);
    reset();
  }, [isVoucherScopeCreateOpen, reset]);

  const handleDeleteVoucherScope = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteVoucherScope(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchVoucherScopes());
            toast.success("Voucher Scope has been deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleEditVoucherScope = useCallback(
    (data) => {
      dismissToast();
      dispatch(editVoucherScope(data, scopeId))
        .then((res) => {
          if (res.status === 500) {
            setIsVoucherScopeEditOpen(true);
            toast.error("Voucher Scope already exists");
          } else {
            setIsVoucherScopeEditOpen(false);
            dispatch(fetchVoucherScopes());
            toast.success(`${data.scopeName} has been updated Successfully`);
          }
        })
        .catch((error) => console.log("error", error));
    },
    [dispatch, scopeId]
  );

  useEffect(() => {
    dispatch(fetchVoucherCategories());
    dispatch(fetchVoucherScopes());
  }, [dispatch]);

  useEffect(() => {
    if (voucherDetails) {
      setValue("scopeName", voucherDetails?.voucher_scope_name);
      setValue("scopeCode", voucherDetails?.voucher_scope_code);
    }
  }, [setValue, voucherDetails]);

  return (
    <MainContent>
      <Grid columns={2}>
        <Categories
          title="Voucher Categories"
          categories={voucher.categories}
          type="Voucher"
          createCategory={handleCreateVoucherCategory}
          deleteCategory={handleDeleteVoucherCategory}
          editCategory={handleEditVoucherCategory}
          handleModal={handleVoucherModal}
          isModalOpen={isVoucherCreateOpen}
          isEditModalOpen={isVoucherEditModalOpen}
          handleIsEditModal={handleVoucherEditModal}
        />
        <TableCard
          title="Voucher Scopes"
          btnText="Create"
          btnAction={handleToggleCreateScopeModal}
          hasAction
        >
          <tbody>
            {voucher?.scopes?.map(
              ({ id, voucher_scope_code, voucher_scope_name }) => (
                <tr key={id}>
                  <td>{voucher_scope_name}</td>
                  <td>{voucher_scope_code}</td>
                  <td className="align_right">
                    <Button
                      variant="tertiary"
                      handleClick={() => {
                        setIsVoucherScopeEditOpen(true);
                        setScopeId(id);
                        dispatch(fetchVoucherScope(id));
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      handleClick={() => handleDeleteVoucherScope(id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </TableCard>
        {isVoucherScopeCreateOpen && (
          <Modal title="Create Voucher Scope">
            <form onSubmit={handleSubmit(handleCreateVoucherScope)}>
              <Input
                name="scopeName"
                register={register}
                type="text"
                placeholder="Scope Name"
                label="Scope Name"
              />
              <Input
                name="scopeCode"
                register={register}
                type="text"
                placeholder="Scope Code"
                label="Scope Code"
              />
              <Buttons>
                <Button
                  variant="secondary"
                  handleClick={handleToggleCreateScopeModal}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Create
                </Button>
              </Buttons>
            </form>
          </Modal>
        )}
        {isVoucherScopeEditOpen && (
          <Modal title="Edit Voucher Scope">
            <form onSubmit={handleSubmit(handleEditVoucherScope)}>
              <Input
                name="scopeName"
                register={register}
                type="text"
                placeholder="Scope Name"
                label="Scope Name"
              />
              <Input
                name="scopeCode"
                register={register}
                type="text"
                placeholder="Scope Code"
                label="Scope Code"
              />
              <Buttons>
                <Button
                  handleClick={() => setIsVoucherScopeEditOpen(false)}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Buttons>
            </form>
          </Modal>
        )}
      </Grid>
    </MainContent>
  );
};

export default VoucherSettings;
