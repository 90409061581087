import React from "react";
import SwiperCore from "swiper";
import { Pagination, Scrollbar } from "swiper/modules";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/modules/pagination.min.css";
import "swiper/swiper.min.css";
import AdminOperationalDashboard from "../../components/dashboards/admin-operational-dashboard";
import BrandDashboard from "../../components/dashboards/brand-dashboard";
import ClientDashboard from "../../components/dashboards/client-dashboard";
import MerchantDashboard from "../../components/dashboards/merchant-dashboard";
import { fetchDashboardItems } from "../../redux/actions/dashboard.actions";
import * as Styled from "./dashboard-content.style";

SwiperCore.use([Pagination, Scrollbar]);

const generateData = (baseval, count, yrange) => {
  let i = 0;
  let series = [];
  while (i < count) {
    let x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
    let y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    let z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;
    series.push([x, y, z]);
    baseval += 86400000;
    i++;
  }
  return series;
};

const options = {
  colors: ["#3CD17F", "#4B42AD"],
  legend: {
    position: "top",
  },
  chart: {
    height: 350,
    type: "area",
    fontFamily: "Yellix",
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download:
          '<svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" stroke-linecap="square" stroke-linejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>',
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    type: "date",
    categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
};

const series = [
  {
    name: "November",
    data: [31, 40, 28, 51, 42, 109, 100],
  },
  {
    name: "December",
    data: [11, 32, 45, 32, 34, 52, 41],
  },
];

const radialOptions = {
  chart: {
    height: 390,
    type: "radialBar",
    fontFamily: "Yellix",
  },
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: "40%",
        background: "transparent",
        image: undefined,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
    },
  },
  stroke: {
    lineCap: "round",
  },
  colors: ["#27A560", "#2FC673", "#49D488", "#6ADC9E"],
  labels: ["Dairy", "Fruits", "Vegetables", "Meat"],
  legend: {
    show: true,
    floating: true,
    fontSize: "16px",
    position: "left",
    labels: {
      useSeriesColors: false,
    },
    markers: {
      size: 0,
    },
    formatter: function (seriesName, opts) {
      return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
    },
    itemMargin: {
      vertical: 3,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          show: false,
        },
      },
    },
  ],
};

const radialSeries = [76, 67, 61, 90];

const bubbleSeries = [
  {
    name: "Bubble1",
    data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
      min: 10,
      max: 60,
    }),
  },
  {
    name: "Bubble2",
    data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
      min: 10,
      max: 60,
    }),
  },
  {
    name: "Bubble3",
    data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
      min: 10,
      max: 60,
    }),
  },
];
const bubbleOptions = {
  chart: {
    type: "bubble",
    height: 390,
    fontFamily: "Yellix",
  },
  legend: {
    position: "top",
  },
  colors: ["rgb(241, 241, 241)", "#4b42ad", "#3CD17F"],
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 0.8,
  },
  xaxis: {
    tickAmount: 12,
    type: "category",
  },
  yaxis: {
    max: 70,
  },
};

export const DashboardContent = () => {
  const dispatch = useDispatch();

  useEffect(() => dispatch(fetchDashboardItems()), [dispatch]);
  const userRole = useSelector(({ auth }) => auth.role);

  const operationalDashboard = useSelector(({ dashboard }) => {
    return dashboard?.operational;
  });

  const revenueDashboard = useSelector(({ dashboard }) => {
    return dashboard?.revenue;
  });

  return (
    <main className="main_page">
      <Styled.Title>Dashboard</Styled.Title>
      {userRole.is_admin ? (
        <AdminOperationalDashboard
          operational={operationalDashboard}
          revenue={revenueDashboard}
        />
      ) : userRole.is_client || userRole.is_client_user ? (
        <ClientDashboard
          operational={operationalDashboard}
          revenue={revenueDashboard}
        />
      ) : userRole.is_brand || userRole.is_brand_user ? (
        <BrandDashboard
          operational={operationalDashboard}
          revenue={revenueDashboard}
        />
      ) : userRole.is_merchant || userRole.is_merchant_user ? (
        <MerchantDashboard
          operational={operationalDashboard}
          revenue={revenueDashboard}
        />
      ) : (
        <></>
      )}
      {/* <Grid columns={3} style={{ marginTop: "25px", marginBottom: "25px" }}>
              <div className="card">
                <div className="table-header">
                  <h3>Orders - Last 24 Hours</h3>
                </div>
                <div className="table-content">
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr>
                        <td className="col-md-10">Total orders</td>
                        <td className="col-md-2 align_right value_td">0</td>
                      </tr>
                      <tr>
                        <td className="col-md-10">Total lines</td>
                        <td className="col-md-2 align_right value_td">0</td>
                      </tr>
                      <tr>
                        <td className="col-md-10">Total revenue</td>
                        <td className="col-md-2 align_right value_td">£0.00</td>
                      </tr>
                      <tr>
                        <td className="col-md-10">Average order costs</td>
                        <td className="col-md-2 align_right value_td">£0.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        
              <div className="card">
                <div className="table-header flex justify">
                  <h3>All Orders</h3>
                  <div className="table_btns">
                    <a href="/" className="btn btn-tertiary">
                      Manage
                    </a>
                    <a href="/" className="btn btn-tertiary">
                      Stock Alerts
                    </a>
                  </div>
                </div>
                <div className="table-content">
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr>
                        <td>Total products</td>
                        <td className="align_right value_td">AED 345,871,902</td>
                      </tr>
                      <tr>
                        <td>
                          <em>Open</em> stock alerts
                        </td>
                        <td className="align_right value_td">56</td>
                      </tr>
                      <tr>
                        <td>
                          <em>Closed</em> stock alerts
                        </td>
                        <td className="align_right value_td">91</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        
              <div className="card">
                <div className="table-header flex justify">
                  <h3>Customers</h3>
                  <div className="table_btns">
                    <a href="/" className="btn btn-tertiary">
                      Manage
                    </a>
                    <a href="/" className="btn btn-tertiary">
                      Stock Alerts
                    </a>
                  </div>
                </div>
                <div className="table-content">
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr>
                        <td>Total products</td>
                        <td className="align_right value_td">AED 345,871,902</td>
                      </tr>
                      <tr>
                        <td>
                          <em>Open</em> stock alerts
                        </td>
                        <td className="align_right value_td">56</td>
                      </tr>
                      <tr>
                        <td>
                          <em>Closed</em> stock alerts
                        </td>
                        <td className="align_right value_td">91</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Grid>
            <Grid columns={3}>
              <Card title="Latest Orders">
                <Styled.ChartWrapper>
                  <Chart
                    options={radialOptions}
                    series={radialSeries}
                    type="radialBar"
                    height={350}
                    width="100%"
                  />
                </Styled.ChartWrapper>
              </Card>
              
              <Card title="Latest Orders">
                <Styled.ChartWrapper>
                  <Chart
                    options={bubbleOptions}
                    series={bubbleSeries}
                    type="bubble"
                    width="100%"
                    height={350}
                  />
                </Styled.ChartWrapper>
              </Card>
            </Grid> */}
    </main>
  );
};

export default DashboardContent;
