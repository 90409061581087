import axios from "axios";
import API_URL from "../../api/api.constants";
import { getBase64 } from "../../helpers";
import {
  CREATE_MERCHANT_AMENITY_SUCCESS,
  CREATE_MERCHANT_ATTRIBUTE_SUCCESS,
  CREATE_MERCHANT_CATEGORY_SUCCESS,
  DELETE_MERCHANT_AMENITY_SUCCESS,
  DELETE_MERCHANT_ATTRIBUTE_SUCCESS,
  DELETE_MERCHANT_CATEGORY_SUCCESS,
  DELETE_MERCHANT_SUCCESS,
  EDIT_MERCHANT_AMENITY_SUCCESS,
  EDIT_MERCHANT_ATTRIBUTE_SUCCESS,
  EDIT_MERCHANT_CATEGORY_SUCCESS,
  FETCH_BRAND_MERCHANTS_SUCCESS,
  FETCH_BRAND_USER_MERCHANTS_SUCCESS,
  FETCH_CLIENT_MERCHANTS_SUCCESS,
  FETCH_CLIENT_USER_MERCHANTS_SUCCESS,
  FETCH_MERCHANTS_LOADING,
  FETCH_MERCHANTS_SUCCESS,
  FETCH_MERCHANT_AMENITIES_SUCCESS,
  FETCH_MERCHANT_AMENITY_SUCCESS,
  FETCH_MERCHANT_ATTRIBUTES_SUCCESS,
  FETCH_MERCHANT_ATTRIBUTE_SUCCESS,
  FETCH_MERCHANT_CATEGORIES_SUCCESS,
  FETCH_MERCHANT_LOADING,
  FETCH_MERCHANT_SUCCESS,
  FETCH_MERCHANT_USERS_SUCCESS,
  SET_MERCHANT_AMENITIES,
  SET_MERCHANT_ATTRIBUTES,
  SET_MERCHANT_FORM_DATA,
} from "./actions.constants";

export const fetchMerchants = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  dispatch({ type: FETCH_MERCHANTS_LOADING });
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchants}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_MERCHANTS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchMerchant = (id) => (dispatch) => {
  dispatch({ type: FETCH_MERCHANT_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchants}/${id}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_MERCHANT_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchMerchantCategories = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchantCategories}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(
      (data) =>
        data && dispatch({ type: FETCH_MERCHANT_CATEGORIES_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchMerchantUsers = (userId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchants}/${userId}/users`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_MERCHANT_USERS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchMerchantAmenities = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchantAmenities}`, config)
    .then((res) => res.status === 200 && res.data)
    .then((data) => dispatch({ type: FETCH_MERCHANT_AMENITIES_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchMerchantAmenity = (amenityId) => (dispatch) => {
  return axios
    .get(`${API_URL.merchantAmenities}/${amenityId}`)
    .then(({ data }) =>
      dispatch({ type: FETCH_MERCHANT_AMENITY_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchMerchantAttribute = (attributeId) => (dispatch) => {
  return axios
    .get(`${API_URL.merchantAttributes}/${attributeId}`)
    .then(({ data }) =>
      dispatch({ type: FETCH_MERCHANT_ATTRIBUTE_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchMerchantAttributes = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchantAttributes}`, config)
    .then((res) => res.status === 200 && res.data)
    .then((data) => dispatch({ type: FETCH_MERCHANT_ATTRIBUTES_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const deleteMerchant = (merchantId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchants}/${merchantId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_MERCHANT_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const createMerchantCategory = (formData) => async (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  return axios(API_URL.merchantCategories, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      category_name: formData?.categoryName,
    },
  })
    .then((res) => {
      dispatch({ type: EDIT_MERCHANT_CATEGORY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const editMerchantCategory =
  (categoryId, categoryName) => (dispatch) => {
    const accessToken = localStorage.getItem("access-token");

    return axios(`${API_URL.merchantCategories}/${categoryId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        category_name: categoryName,
      },
    })
      .then((res) => {
        dispatch({ type: CREATE_MERCHANT_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const deleteMerchantCategory = (categoryId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchantCategories}/${categoryId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_MERCHANT_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const createMerchantAmenity = (formData) => async (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  const image = await getBase64(formData.amenityIcon[0]);

  return axios(API_URL.merchantAmenities, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      amenity_name: formData?.amenityName,
      amenity_icon: image,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_MERCHANT_AMENITY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const deleteMerchantAmenity = (amenityId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchantAmenities}/${amenityId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_MERCHANT_AMENITY_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const createMerchantAttribute = (formData) => async (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  const image = await getBase64(formData.attributeIcon[0]);

  return axios(API_URL.merchantAttributes, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      attribute_name: formData?.attributeName,
      attribute_icon: image,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_MERCHANT_ATTRIBUTE_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const deleteMerchantAttribute = (attributeId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchantAttributes}/${attributeId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_MERCHANT_ATTRIBUTE_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const fetchBrandMerchants = (userId) => (dispatch) => {
  dispatch({ type: FETCH_MERCHANTS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brands}/${userId}/merchants`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_BRAND_MERCHANTS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchBrandUserMerchants = (brandId) => (dispatch) => {
  dispatch({ type: FETCH_MERCHANTS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brands}/${brandId}/merchants`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_BRAND_USER_MERCHANTS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchClientMerchants = (userId) => (dispatch) => {
  dispatch({ type: FETCH_MERCHANTS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clients}/${userId}/merchants`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_CLIENT_MERCHANTS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchClientUserMerchants = (clientId) => (dispatch) => {
  dispatch({ type: FETCH_MERCHANTS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clients}/${clientId}/merchants`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_CLIENT_USER_MERCHANTS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const editMerchantAmenity =
  (formData, amenityId, amenityIcon) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = formData.amenityIcon[0]
      ? await getBase64(formData.amenityIcon[0])
      : amenityIcon;

    return axios(`${API_URL.merchantAmenities}/${amenityId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        amenity_name: formData?.amenityName,
        amenity_icon: image,
      },
    })
      .then((res) => {
        dispatch({ type: EDIT_MERCHANT_AMENITY_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const editMerchantAttribute =
  (formData, attributeId, attributeIcon) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = formData.attributeIcon[0]
      ? await getBase64(formData.attributeIcon[0])
      : attributeIcon;

    return axios(`${API_URL.merchantAttributes}/${attributeId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        attribute_name: formData?.attributeName,
        attribute_icon: image,
      },
    })
      .then((res) => {
        dispatch({ type: EDIT_MERCHANT_ATTRIBUTE_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const updateFormData = (data) => (dispatch) =>
  dispatch({ type: SET_MERCHANT_FORM_DATA, data });

export const updateMerchantAmenities = (data) => (dispatch) =>
  dispatch({ type: SET_MERCHANT_AMENITIES, data });

export const updateMerchantAttributes = (data) => (dispatch) =>
  dispatch({ type: SET_MERCHANT_ATTRIBUTES, data });
