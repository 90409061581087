import React, { useEffect } from "react";

import { MainContent, TableCard } from "@vms-admin/components";
import { useParams } from "react-router-dom";
import { removeCharacters } from "../../helpers";
import { fetchActivityLog } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export const ActivityLogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const logDetails = useSelector((state) => state.admin.activityLog);

  useEffect(() => dispatch(fetchActivityLog(id)), [dispatch, id]);
  // let stateAfterObj = logDetails?.state_after?.split(":");
  let stateBeforeArray = removeCharacters(logDetails?.state_before)?.split(":");
  let stateAfterArray = removeCharacters(logDetails?.state_after)?.split(":");

  return (
    <MainContent>
      <TableCard title={`Log #${id} Details`}>
        <tbody>
          <tr>
            <td>
              <strong>Action</strong>
            </td>
            <td>
              {logDetails.action === "DELETE" && (
                <span className="label label-delete">{logDetails.action}</span>
              )}
              {logDetails.action === "UPDATE" && (
                <span className="label label-update">{logDetails.action}</span>
              )}
              {logDetails.action === "CREATE" && (
                <span className="label label-create">{logDetails.action}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <strong>User Role</strong>
            </td>
            <td>{logDetails.user_role}</td>
          </tr>
          <tr>
            <td>
              <strong>User Email</strong>
            </td>
            <td>{logDetails.user_email}</td>
          </tr>
          <tr>
            <td>
              <strong>Target Id</strong>
            </td>
            <td>{logDetails.target_id}</td>
          </tr>
          <tr>
            <td>
              <strong>Target Type</strong>
            </td>
            <td>{logDetails.target_type}</td>
          </tr>
          <tr>
            <td>
              <strong>Status before</strong>
            </td>
            <td>
              {logDetails?.state_before ? (
                <>
                  <strong>{stateBeforeArray[0]}</strong>
                  <span>{stateBeforeArray[1]}</span>
                </>
              ) : (
                "-"
              )}
            </td>
          </tr>

          <tr>
            <td>
              <strong>Status After</strong>
            </td>
            <td>
              {logDetails?.state_after ? (
                <>
                  <strong>{stateAfterArray[0]}</strong>
                  <span>{stateAfterArray[1]}</span>
                </>
              ) : (
                "-"
              )}
            </td>
          </tr>
        </tbody>
      </TableCard>
    </MainContent>
  );
};

export default ActivityLogDetails;
