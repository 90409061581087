import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInView } from "react-intersection-observer";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import toast from "react-hot-toast";

import { brandSchema } from "../../schema";
import {
  Input,
  Button,
  Map,
  UploadField,
  ErrorMessage,
  Card,
  MainContent,
  Grid,
  Buttons,
} from "@vms-admin/components";

import {
  fetchCountries,
  fetchCountryInfo,
  fetchClients,
  fetchBrandCategories,
  editBrand,
  fetchBrand,
} from "../../redux/actions";
import {
  getCountryOptions,
  getCityOptions,
  getCategoryOptions,
  getClientOptions,
} from "@vms-admin/utils";
import { dismissToast, somethignWentWrong } from "../../helpers";
import EditBrandLoader from "./edit-brand-loader";

export const EditBrand = () => {
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const clients = useSelector(({ clients }) => clients.clients);
  const brandData = useSelector(({ brands }) => brands.brand);
  const brandLoading = useSelector(({ brands }) => brands.brandLoading);
  const brandCategories = useSelector(({ brands }) => brands.categories);
  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );

  const { ref, inView } = useInView();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(brandSchema),
  });

  const brandClient = useMemo(
    () =>
      clients &&
      clients.filter((client) => client.id === brandData?.client?.id)[0],
    [clients, brandData?.client]
  );

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
    },
    [setValue, dispatch]
  );

  const handleEditBrand = useCallback(
    (data) => {
      dismissToast();
      dispatch(editBrand(data, brandData, id, address, lat, lng))
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => history.push("/brand-management"), [2000]);
            toast.success(`${data.name} has been updated Successfully`);
          } else if (res.status === 409) {
            toast.error("User already exist");
          } else if (res.status === 401) {
            history.push("/");
          } else {
            somethignWentWrong();
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, address, lat, lng, history, brandData, id]
  );

  useEffect(() => {
    setValue("name", brandData.brand_name);
    setValue("email", brandData.email);
    setValue("mobileNumber", brandData.telephone);
    console.log("brandClient", brandClient);
    setValue("clientName", {
      value: brandClient?.client_name,
      label: brandClient?.client_name,
      id: brandClient?.id,
    });
    setValue("category", {
      value: brandData?.brand_category?.category_name,
      label: brandData?.brand_category?.category_name,
    });
    setValue("countryName", {
      value: brandData.country,
      label: brandData.country,
    });
    setValue("cityName", {
      value: brandData.city,
      label: brandData.city,
    });
    setValue("poBox", brandData.po_box);
    setValue("address", brandData.address);
  }, [brandData, setValue, brandClient]);

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCountryInfo(brandData?.country?.id));
    dispatch(fetchClients());
    dispatch(fetchBrandCategories());
  }, [dispatch, brandData]);

  useEffect(() => dispatch(fetchBrand(id)), [dispatch, id]);

  useEffect(
    () =>
      !brandData &&
      toast.error(
        "It looks like there is an issue, please try again in few minutes "
      ),
    [brandData]
  );

  return (
    <MainContent>
      <form onSubmit={handleSubmit(handleEditBrand)} noValidate>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Brand Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Link to="/brand-management" className="btn btn-secondary">
                  Back
                </Link>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        {brandLoading ? (
          <EditBrandLoader />
        ) : (
          <div className="cards_wrapper">
            <Card title="Edit Brand">
              <Grid columns={3}>
                <Input
                  label="Brand Name"
                  type="text"
                  placeholder="Brand Name"
                  name="name"
                  register={register}
                  errors={errors}
                />
                <Input
                  label="Email Address"
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  register={register}
                  errors={errors}
                />
                <div className="input_holder">
                  <label>Client Name</label>
                  <Controller
                    name="clientName"
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        options={getClientOptions(clients)}
                        placeholder="Select Client"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["clientName"] && (
                    <ErrorMessage text="Client Name is Required" />
                  )}
                </div>
              </Grid>
              <Grid columns={2}>
                <Input
                  label="Brand Mobile Number"
                  type="tel"
                  placeholder="Brand Mobile Number"
                  name="mobileNumber"
                  register={register}
                  errors={errors}
                />
                <div className="input_holder">
                  <label>Brand Category</label>
                  <Controller
                    name="category"
                    errors={errors}
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        options={getCategoryOptions(brandCategories)}
                        placeholder="Select Category"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["category"] && (
                    <ErrorMessage text="Brand Category is Required" />
                  )}
                </div>
              </Grid>
              <UploadField
                label="Brand Logo"
                name="logo"
                image={brandData?.image}
                errors={errors}
                register={register}
              />
            </Card>
            <Card title="Location">
              <Grid columns={3}>
                <div className="input_holder">
                  <label>Country Name</label>
                  <Controller
                    name="countryName"
                    errors={errors}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        menuPortalTarget={document.body}
                        options={getCountryOptions(
                          countryManagementState.countries
                        )}
                        placeholder="Select Country"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        value={value ? value : ""}
                      />
                    )}
                  />
                  {errors && errors["countryName"] && (
                    <ErrorMessage text="Country is Required" />
                  )}
                </div>
                <div className="input_holder">
                  <label>City Name</label>
                  <Controller
                    name="cityName"
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        options={getCityOptions(
                          countryManagementState?.countryInfo?.cities
                        )}
                        isLoading={countryManagementState?.countryInfoLoading}
                        loadingMessage={() => "Loading..."}
                        placeholder="Select City"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["cityName"] && (
                    <ErrorMessage text="City is Required" />
                  )}
                </div>
                <Input
                  label="Brand P.O. Box"
                  type="text"
                  placeholder="Brand P.O. Box"
                  name="poBox"
                  register={register}
                  errors={errors}
                />
              </Grid>

              <Map
                addressInfo={(address, lat, lng) => {
                  setAddress(address);
                  setLat(lat);
                  setLng(lng);
                }}
                address={brandData?.address}
                userLat={Number(brandData?.lat)}
                userLng={Number(brandData?.long)}
              />
            </Card>
          </div>
        )}
      </form>
    </MainContent>
  );
};

export default EditBrand;
