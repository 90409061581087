import React, { useCallback, useState } from "react";
// import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import {
  TableCard,
  Button,
  Modal,
  ToolbarButton,
  Buttons,
  TableCardLoader,
} from "@vms-admin/components";
import { useDispatch } from "react-redux";
import {
  fetchBrandMerchants,
  fetchBrandUserMerchants,
  fetchClientMerchants,
  fetchClientUserMerchants,
  fetchMerchants,
  deleteMerchant,
} from "../../redux/actions";
import {
  active,
  dismissToast,
  inActive,
  somethignWentWrong,
} from "../../helpers";

export const MerchantsTable = ({
  merchants,
  hasActions = true,
  merchantsLoading,
}) => {
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [target, setTarget] = useState({ name: "", id: 0 });
  const userRole = useSelector(({ auth }) => auth.role);
  const dispatch = useDispatch();

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const handleDeleteMerchant = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteMerchant(id))
        .then((res) => {
          if (res.status === 204) {
            toggleModal();
            toast.success(`Merchant has been deleted Successfully`);
            userRole.is_client && dispatch(fetchClientMerchants(userInfo?.id));
            userRole.is_client_user &&
              dispatch(fetchClientUserMerchants(userInfo?.client_id));

            userRole.is_brand && dispatch(fetchBrandMerchants(userInfo?.id));
            userRole.is_brand_user &&
              dispatch(fetchBrandUserMerchants(userInfo?.brand_id));

            userRole.is_admin && dispatch(fetchMerchants());
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, userInfo, userRole, toggleModal]
  );

  return (
    <>
      <TableCard
        title="Merchant Management"
        btnText="Create New Merchant"
        btnUrl="/create-merchant"
        hasLink={!userRole.is_client && true && hasActions}
      >
        {!merchants?.length && !merchantsLoading && (
          <tbody>
            <tr>
              <td className="empty_list_message">No Merchants found</td>
            </tr>
          </tbody>
        )}
        {!merchantsLoading ? (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>Merchant Name</th>
                <th>Email Address</th>
                <th>Client Name</th>
                <th>Brand Name</th>
                <th>Status</th>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {merchants?.map(
                (
                  { id, merchant_name, email, confirmed, client, brand },
                  index
                ) => (
                  <tr
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    key={id}
                  >
                    <td>{index + 1}</td>
                    <td>
                      <span title={merchant_name} className="text-ellipsis">
                        {merchant_name}
                      </span>
                    </td>
                    <td>
                      <span title={email} className="text-ellipsis">
                        {email}
                      </span>
                    </td>
                    <td>{client?.client_name}</td>
                    <td>{brand?.brand_name}</td>
                    <td>
                      {confirmed ? active("Confirmed") : inActive("Pending")}
                    </td>
                    <td>
                      <ToolbarButton
                        btns={[
                          { btnUrl: `/merchant/${id}`, btnText: "View" },
                          {
                            btnUrl: `/edit-merchant/${id}`,
                            btnText: "Edit",
                            show: hasActions,
                          },
                          {
                            handleClick: () => {
                              toggleModal();
                              setTarget({ id, name: merchant_name });
                            },
                            btnText: "Delete",
                            show: hasActions,
                          },
                        ]}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </>
        ) : (
          <TableCardLoader />
        )}
      </TableCard>
      {isModalOpen && (
        <Modal title="Delete">
          Are you sure you want to delete {target.name}?
          <Buttons>
            <Button variant="secondary" handleClick={toggleModal}>
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              handleClick={() => handleDeleteMerchant(target.id)}
            >
              Confirm
            </Button>
          </Buttons>
        </Modal>
      )}
    </>
  );
};

export default MerchantsTable;
