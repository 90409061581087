import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import {
  TableCard,
  Button,
  Modal,
  ToolbarButton,
  Buttons,
  TableCardLoader,
} from "@vms-admin/components";
import {
  deleteBrand,
  fetchBrands,
  fetchClientBrands,
  fetchClientUserBrands,
} from "../../redux/actions";
import {
  active,
  dismissToast,
  inActive,
  somethignWentWrong,
} from "../../helpers";

export const BrandsTable = ({ brands, hasActions = true, brandsLoading }) => {
  const [target, setTarget] = useState({ name: "", id: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const userRole = useSelector(({ auth }) => auth.role);
  const dispatch = useDispatch();

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const handleDeleteBrand = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteBrand(id))
        .then((res) => {
          if (res.status === 204) {
            toast.success(`Brand has been deleted Successfully`);
            toggleModal();
            userRole.is_client && dispatch(fetchClientBrands(userInfo?.id));
            userRole.is_client_user &&
              dispatch(fetchClientUserBrands(userInfo?.client_id));

            userRole.is_admin && dispatch(fetchBrands());
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, userInfo, userRole, toggleModal]
  );

  return (
    <>
      <TableCard
        title="Brand Management"
        btnText="Create New Brand"
        btnUrl="/create-brand"
        hasLink={hasActions}
      >
        {!brands?.length && !brandsLoading && (
          <tbody>
            <tr>
              <td className="empty_list_message">No Brands found</td>
            </tr>
          </tbody>
        )}
        {!brandsLoading ? (
          <>
            <thead>
              <tr>
                <td>#</td>
                <td>Brand Name</td>
                <td>Email Address</td>
                <td>Client Name</td>
                <td>Status</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {brands?.map(
                ({ email, client, brand_name, confirmed, id }, index) => (
                  <tr
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    key={id}
                  >
                    <td>{index + 1}</td>
                    <td>
                      <span title={brand_name} className="text-ellipsis">
                        {brand_name}
                      </span>
                    </td>
                    <td>
                      <span title={email} className="text-ellipsis">
                        {email}
                      </span>
                    </td>
                    <td>
                      <span
                        title={client?.client_name}
                        className="text-ellipsis"
                      >
                        {client?.client_name}
                      </span>
                    </td>
                    <td>
                      {confirmed ? active("Confirmed") : inActive("Pending")}
                    </td>
                    <td>
                      <ToolbarButton
                        btns={[
                          { btnUrl: `/brand/${id}`, btnText: "View" },
                          {
                            btnUrl: `/edit-brand/${id}`,
                            btnText: "Edit",
                            show: hasActions,
                          },
                          {
                            handleClick: () => {
                              toggleModal();
                              setTarget({ id, name: brand_name });
                            },
                            btnText: "Delete",
                            show: hasActions,
                          },
                        ]}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </>
        ) : (
          <TableCardLoader />
        )}
      </TableCard>

      {isModalOpen && (
        <Modal title="Delete">
          Are you sure you want to delete {target.name}?
          <Buttons>
            <Button variant="secondary" handleClick={toggleModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              handleClick={() => handleDeleteBrand(target.id)}
            >
              Confirm
            </Button>
          </Buttons>
        </Modal>
      )}
    </>
  );
};

export default BrandsTable;
