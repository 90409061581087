import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import reportWebVitals from "./reportWebVitals";
// import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
import App from "./App";
import GlobalStyle from "./globals/style";
import theme from "./globals/theme";
import "./index.css";
import "./kmmrce-theme.css";
import store from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <Router>
          <App />
        </Router>
        {/* </PersistGate> */}
      </Provider>
      <GlobalStyle />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
