import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";

import {
  UploadField,
  Input,
  Button,
  Modal,
  Buttons,
} from "@vms-admin/components";
import * as Styled from "./categories.style";
import { createCategorySchema, editCategorySchema } from "../../schema";
import { usePagination } from "../../hooks/usePagination";

export const Categories = ({
  categories,
  type,
  isModalOpen,
  handleModal,
  createCategory,
  handleIsEditModal,
  editCategory,
  isEditModalOpen,
  deleteCategory,
  title,
}) => {
  const [updatedValue, setUpdatedValue] = useState({ name: "", icon: "" });
  const [selected, setSelected] = useState("");
  const isVoucher = useMemo(() => type.toLowerCase() === "voucher", [type]);

  const {
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createCategorySchema),
  });

  const getFilteredCategories = useCallback(() => {
    if (!watch("search")) {
      return categories;
    } else {
      return categories.filter((categories) =>
        categories.category_name
          .toLowerCase()
          .includes(watch("search").toLowerCase())
      );
    }
  }, [watch, categories]);

  const { firstIndex, lastIndex } = usePagination(getFilteredCategories(), 5);

  const {
    register: editRegister,
    handleSubmit: editSubmit,
    setValue,
    formState: { errors: editErrors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(editCategorySchema),
  });

  const handleEditModal = useCallback(
    (id, prevCategoryName, prevImage) => {
      handleIsEditModal();
      setUpdatedValue({ name: prevCategoryName, icon: prevImage });
      setSelected(id);
    },
    [handleIsEditModal]
  );

  useEffect(() => {
    setValue("editCategoryName", updatedValue.name);
  }, [updatedValue, setValue]);

  const handleEdit = useCallback(
    (data) =>
      editCategory(
        selected,
        data.editCategoryName,
        data.editCategoryIcon,
        updatedValue
      ),
    [editCategory, selected, updatedValue]
  );

  const closeCreateModal = useCallback(() => {
    handleModal();
    reset();
  }, [reset, handleModal]);

  const closeEditModal = useCallback(() => {
    handleEditModal();
  }, [handleEditModal]);

  return (
    <div>
      <div className="card">
        <div className="card-header flex justify">
          <h3>{title}</h3>
          <div className="card-btns">
            <Button variant="tertiary" handleClick={handleModal}>
              Create
            </Button>
          </div>
        </div>
        <div className="card-content">
          <table className="table table-bordered table-hover">
            <tbody>
              {getFilteredCategories()
                ?.slice(firstIndex, lastIndex)
                ?.map(({ id, category_name, voucher_category_icon }, index) => (
                  <motion.tr
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    key={id}
                  >
                    <td>{index + 1}</td>
                    {isVoucher && (
                      <td>
                        <Styled.Icon
                          src={
                            voucher_category_icon !== "null"
                              ? voucher_category_icon
                              : `/images/image-placeholder.png`
                          }
                          alt={category_name}
                        />
                      </td>
                    )}
                    <td>{category_name}</td>
                    <td>
                      <Buttons noMargin>
                        <Button
                          variant="danger"
                          handleClick={() => {
                            deleteCategory(id);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="tertiary"
                          handleClick={() =>
                            handleEditModal(
                              id,
                              category_name,
                              voucher_category_icon
                            )
                          }
                        >
                          Edit
                        </Button>
                      </Buttons>
                    </td>
                  </motion.tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* {getFilteredCategories()?.length > 5 && (
        <Pagination
          canGoNext={canGoNext}
          canGoPrev={canGoPrev}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          pagesArray={pagesArray}
          pageDecrementBtn={pageDecrementBtn}
          pageIncementBtn={pageIncementBtn}
        />
      )} */}

      {isModalOpen && (
        <Modal title={`Create ${type} Category`}>
          <form noValidate onSubmit={handleSubmit(createCategory)}>
            <Input
              type="text"
              name="categoryName"
              placeholder="Category Name"
              register={register}
              errors={errors}
              label="Category Name"
            />
            {isVoucher && (
              <UploadField
                name="categoryIcon"
                register={register}
                errors={errors}
                label="Category Icon"
              />
            )}
            <Buttons>
              <Button variant="secondary" handleClick={closeCreateModal}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Create
              </Button>
            </Buttons>
          </form>
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal title={`Edit ${type} Category`}>
          <form noValidate onSubmit={editSubmit(handleEdit)}>
            <Input
              type="text"
              name="editCategoryName"
              placeholder="Category Name"
              register={editRegister}
              errors={editErrors}
              label="Category Name"
            />
            {isVoucher && (
              <UploadField
                name="editCategoryIcon"
                register={editRegister}
                errors={editErrors}
                label="Category Icon"
                image={updatedValue.icon}
              />
            )}
            <Buttons>
              <Button variant="secondary" handleClick={closeEditModal}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Buttons>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Categories;
