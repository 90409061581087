import { motion } from "framer-motion";
import styled from "styled-components";

export const Grid = styled(motion.div)`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "2.5fr 1fr"};
  grid-gap: 25px;
  // margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
`;
