import styled from "styled-components";

export const AccountSettings = styled.div`
  .react-tabs__tab-list {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: 1px solid ${(props) => props.theme.colors.gray};
    margin-bottom: 1px;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .card:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }
`;

export const Title = styled.h3`
  font-size: 22px;
  padding-top: 24px;
  padding-bottom: 20px;
  margin-bottom: 27px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  font-weight: 500;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  * {
    margin-right: 16px;
  }
`;

export const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
