import React from "react";
import Skeleton from "react-loading-skeleton";

export const TableCardLoader = () => (
  <>
    <thead>
      <tr>
        <th width={100}>
          <Skeleton width={15} height={15} />
        </th>
        <th>
          <Skeleton width={50} height={15} />
        </th>
        <th>
          <Skeleton width={50} height={15} />
        </th>
        <th>
          <Skeleton width={50} height={15} />
        </th>
        <th>
          <Skeleton width={50} height={15} />
        </th>
        <th>
          <Skeleton width={50} height={15} />
        </th>
      </tr>
    </thead>
    <tbody>
      {Array.from({ length: 7 }, (_, i) => (
        <tr>
          <td width={100}>
            <Skeleton width={15} height={15} />
          </td>
          <td>
            <Skeleton width={200} height={15} />
          </td>
          <td>
            <Skeleton width={200} height={15} />
          </td>
          <td>
            <Skeleton width={150} height={15} />
          </td>
          <td>
            <Skeleton width={100} height={15} />
          </td>
          <td className="flex justify-center">
            <Skeleton width={10} height={15} />
          </td>
        </tr>
      ))}
    </tbody>
  </>
);

export default TableCardLoader;
