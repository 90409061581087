import {
  FETCH_PLANS_BY_COUNTRY_SUCCESS,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_BY_CITY_SUCCESS,
  SET_SUBSCRIPTION_ID,
  FETCH_PLAN_SUCCESS,
  FETCH_PLANS_LOADING,
  FETCH_PLAN_LOADING,
} from "../actions/actions.constants";

const initialState = {
  plans: [],
  plansLoading: true,
  plan: {},
  planLoading: false,
  plansByCountry: [],
  plansByCity: [],
  subscriptionId: "",
};

const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLANS_SUCCESS:
      return { ...state, plans: action.plans, plansLoading: false };
    case FETCH_PLANS_LOADING:
      return { ...state, plansLoading: true };
    case FETCH_PLAN_SUCCESS:
      return { ...state, plan: action.plan, planLoading: false };
    case FETCH_PLAN_LOADING:
      return { ...state, planLoading: true };
    case FETCH_PLANS_BY_COUNTRY_SUCCESS:
      return { ...state, plansByCountry: action.plans };
    case FETCH_PLANS_BY_CITY_SUCCESS:
      return { ...state, plansByCity: action.plans };
    case SET_SUBSCRIPTION_ID:
      return { ...state, subscriptionId: action.id };
    default:
      return state;
  }
};
export default plansReducer;
