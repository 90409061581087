import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { MainContent, TableCard } from "@vms-admin/components";
import API_URL from "../../api/api.constants";
import axios from "axios";

export const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  // const user = useSelector((state) => state);
  console.log("uuu", user);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const usersApi = useMemo(() => `${userInfo?.role}s`, [userInfo]);

  const getUser = useCallback(async () => {
    const accessToken = localStorage.getItem("access-token");

    axios(
      `${API_URL[usersApi?.replace("_user", "")]}/${userInfo?.id}/users/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => res.status === 200 && res.data)
      .then(({ data }) => setUser(data))
      .catch((error) => console.log("Error", error));
  }, [userInfo?.id, id, usersApi]);

  // useEffect(() => dispatch(fetchUser(id)), [dispatch, id]);
  useEffect(() => {
    if (userInfo?.id && usersApi) {
      getUser();
    }
  }, [getUser, userInfo?.id, usersApi]);

  return (
    <MainContent>
      <div className="cards_wrapper">
        <TableCard title={`${user?.first_name} ${user?.last_name} Details`}>
          <tbody>
            <tr>
              <td>
                <strong>User Name</strong>
              </td>
              <td>
                {user?.first_name} {user?.last_name}
              </td>
            </tr>
            <tr>
              <td>
                <strong>User Email</strong>
              </td>
              <td>{user?.email}</td>
            </tr>
            <tr>
              <td>
                <strong>Confirmed</strong>
              </td>
              <td>
                {user?.confirmed ? (
                  <span className="label label-create">True</span>
                ) : (
                  <span className="label label-delete">False</span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Is Admin</strong>
              </td>
              <td>
                {user?.is_admin ? (
                  <span className="label label-create">True</span>
                ) : (
                  <span className="label label-delete">False</span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Role</strong>
              </td>
              <td>{user?.role?.split("_").join(" ")}</td>
            </tr>

            <tr>
              <td>
                <strong>User Image</strong>
              </td>
              <td>
                <img src={user?.avatar} alt={user?.first_name} width="150" />
              </td>
            </tr>
          </tbody>
        </TableCard>
        {user?.merchant && (
          <TableCard title="Merchant Details">
            <tr>
              <td>
                <strong>Name</strong>
              </td>
              <td>{user?.merchant?.merchant_name}</td>
            </tr>
            <tr>
              <td>
                <strong>Email</strong>
              </td>
              <td>{user?.merchant?.email}</td>
            </tr>
          </TableCard>
        )}
      </div>
    </MainContent>
  );
};

export default UserDetails;
