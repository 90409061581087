import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { TableCard, Map, Button } from "@vms-admin/components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCustomer } from "../../redux/actions";
import { formatDate, formatTime } from "../../helpers";
import CustomerDetailsLoader from "./customer-details-loader";
import { Grid, Card } from "@vms-admin/components";
import styled from "styled-components";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import MainContent from "../../components/main-content/main-content";
import { useHistory } from "react-router";

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 7px;
`;

const containerStyle = {
  height: "400px",
};

const libraries = ["places"];

export const CustomerDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const customerDetails = useSelector(({ customer }) => customer.customer);
  const customerLoading = useSelector(
    ({ customer }) => customer.customerLoading
  );

  const history = useHistory();

  const mapRef = useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    if (customerDetails?.merchant_redemptions) {
      fitBounds();
    }
  }, []);

  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    customerDetails?.merchant_redemptions?.map((item) => {
      bounds.extend({
        lat: item.lat,
        lng: item.long,
      });
      return item.id;
    });
    mapRef.current?.fitBounds(bounds);

    mapRef.current?.setCenter(bounds.getCenter());
  };

  useEffect(() => {
    if (customerDetails?.merchant_redemptions && mapRef.current) {
      fitBounds();
    }
  }, [customerDetails?.merchant_redemptions, mapRef.current]);

  const [selected, setSelected] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDExax1_3uobjt6iIS7SMxsQUXJDbd9DA0",
    libraries,
  });

  useEffect(() => dispatch(fetchCustomer(id)), [dispatch, id]);

  return (
    <div className="main_page overflow-hidden ">
      {customerLoading ? (
        <CustomerDetailsLoader />
      ) : (
        <div className="lg:flex">
          <div className="lg:w-6/12 px-2">
            <Card title={`Profile`} className="">
              <Grid columns={2}>
                <div className="pr-4">
                  <Avatar
                    src={customerDetails?.image}
                    alt={customerDetails?.firstname}
                    className="ml-4"
                  />
                  <div className="ml-4 mt-5">
                    <strong className="text-xl">First Name</strong>
                    <p>{customerDetails?.firstname}</p>
                  </div>
                  <div className="ml-4 mt-5">
                    <strong className="text-xl">Last Name</strong>
                    <p>{customerDetails?.lastname}</p>
                  </div>
                  <div className="ml-4 mt-5">
                    <strong className="text-xl">Confirmation Status</strong>
                    <div>
                      {customerDetails?.confirmed ? (
                        <span className="label label-create">True</span>
                      ) : (
                        <span className="label label-delete">False</span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="lg:ml-0 ml-4  mt-5">
                    <strong className="text-xl">Customer UID</strong>
                    <p>{customerDetails?.customer_uid}</p>
                  </div>
                  <div className="lg:ml-0 ml-4 mt-5">
                    <strong className="text-xl">Member Since</strong>
                    <p>
                      {customerDetails?.created_at &&
                        new Date(customerDetails?.created_at).toLocaleString()}
                    </p>
                  </div>
                  <div className="lg:ml-0 ml-4 mt-5">
                    <strong className="text-xl">Email</strong>
                    <p>{customerDetails?.email}</p>
                  </div>
                  <div className="lg:ml-0 ml-4 mt-5">
                    <strong className="text-xl">Contact Number</strong>
                    <p>{customerDetails?.phone}</p>
                  </div>
                  <div className="lg:ml-0 ml-4 mt-5">
                    <strong className="text-xl">Phone Verified</strong>
                    <div>
                      {customerDetails?.phone_verfified ? (
                        <span className="label label-create">True</span>
                      ) : (
                        <span className="label label-delete">False</span>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <div className="ml-4 mt-5">
                <strong className="text-xl">Address</strong>
                <div>{customerDetails?.address}</div>
              </div>
              <div className="mt-10">
                <Map
                  userLat={Number(customerDetails?.lat)}
                  userLng={Number(customerDetails?.long)}
                  noAddressField
                />
              </div>
            </Card>
            <TableCard title="Subscriptions" className="mt20">
              {!customerDetails?.customer_subscriptions?.length && (
                <tbody>
                  <tr>
                    <td className="empty_message">No Subscriptions yet</td>
                  </tr>
                </tbody>
              )}
              {customerDetails?.customer_subscriptions?.length > 0 && (
                <>
                  <thead>
                    <tr>
                      <th>Plan Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>City Code</th>
                      <th>Country Code</th>
                      <th>Active</th>
                      <th>Auto renew</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerDetails?.customer_subscriptions?.map(
                      ({ subscription_plan, active, is_autorenew }) => (
                        <tr>
                          <td>{subscription_plan.plan_name}</td>
                          <td>
                            {formatDate(subscription_plan.start_date)}{" "}
                            {formatTime(subscription_plan.start_date)}
                          </td>
                          <td>
                            {formatDate(subscription_plan.end_date)}{" "}
                            {formatTime(subscription_plan.end_date)}
                          </td>
                          <td>{subscription_plan.city_code}</td>
                          <td>{subscription_plan.country_code}</td>

                          <td>
                            {active ? (
                              <span className="label label-create">True</span>
                            ) : (
                              <span className="label label-delete">False</span>
                            )}
                          </td>
                          <td>
                            {is_autorenew ? (
                              <span className="label label-create">True</span>
                            ) : (
                              <span className="label label-delete">False</span>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </>
              )}
            </TableCard>
          </div>
          <div className="lg:w-6/12 px-2">
            <TableCard title="Voucher Redemptions">
              {!customerDetails?.customer_redemptions?.length && (
                <tbody>
                  <tr>
                    <td className="empty_message">No Redeemptions yet</td>
                  </tr>
                </tbody>
              )}
              {customerDetails?.customer_redemptions?.length > 0 && (
                <>
                  <thead>
                    <tr>
                      <th>Voucher Name</th>
                      <th>Redeemed at</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Merchant Name</th>
                      <th>Merchant Email</th>
                      <th>Active</th>
                      <th>Approved</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerDetails?.customer_redemptions?.map(
                      ({ voucher, redemption_time }) => (
                        <tr>
                          <td>{voucher?.voucher_set_name}</td>
                          <td>
                            {formatDate(redemption_time)}{" "}
                            {formatTime(redemption_time)}
                          </td>
                          <td>
                            {formatDate(voucher?.start_datetime)}{" "}
                            {formatTime(voucher?.start_datetime)}
                          </td>
                          <td>
                            {formatDate(voucher?.end_datetime)}{" "}
                            {formatTime(voucher?.end_datetime)}
                          </td>
                          <td>{voucher?.merchant?.merchant_name}</td>
                          <td>{voucher?.merchant?.email}</td>

                          <td>
                            {voucher?.is_active ? (
                              <span className="label label-create">True</span>
                            ) : (
                              <span className="label label-delete">False</span>
                            )}
                          </td>
                          <td>
                            {voucher?.approved ? (
                              <span className="label label-create">True</span>
                            ) : (
                              <span className="label label-delete">False</span>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </>
              )}
            </TableCard>
            <Card title="Voucher Redeemed location" className="mt20">
              <div style={{ borderRadius: 7, overflow: "hidden" }}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  zoom={10}
                  // onClick={addressInfo && handleMapClick}
                  onLoad={onMapLoad}
                  options={{
                    clickableIcons: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                  }}
                >
                  {customerDetails?.merchant_redemptions?.map(
                    (detail, index) => (
                      <Marker
                        key={index}
                        position={{
                          lat: Number(detail?.lat),
                          lng: Number(detail?.long),
                        }}
                        onClick={() => {
                          setSelected(detail);
                        }}
                      >
                        {selected ? (
                          <InfoWindow onCloseClick={() => setSelected(null)}>
                            <div>
                              <div>
                                <h5>{detail?.merchant_name}</h5>
                              </div>

                              <div className="mt-4">
                                Redeemed Count: {detail?.redemption_count}
                              </div>

                              <div className="flex justify-center mt-5">
                                <Button
                                  to={`/merchants/${detail?.merchant_id}`}
                                  className="btn btn-primary"
                                  handleClick={() => {
                                    history.push(
                                      `/merchant/${detail?.merchant_id}`
                                    );
                                  }}
                                >
                                  View Merchant Details
                                </Button>
                              </div>
                            </div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    )
                  )}
                </GoogleMap>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;
