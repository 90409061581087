import React from "react";

export const AdminPanel = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_20851"
      data-name="Group 20851"
      transform="translate(-1663 -688)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(1663 688)"
        fill="rgba(255,255,255,0)"
      />
      <g id="noun-cog-1208909" transform="translate(1458.102 564.055)">
        <path
          id="Path_25686"
          data-name="Path 25686"
          d="M141.4,142.384a1.44,1.44,0,0,1-1.923-.543l-.725-1.258a1.44,1.44,0,0,1,.5-1.939l.457-.28a1.207,1.207,0,0,0,.519-.928v-1.984a1.209,1.209,0,0,0-.52-.929l-.457-.28a1.442,1.442,0,0,1-.495-1.939l.726-1.257a1.439,1.439,0,0,1,1.926-.541l.471.256a1.209,1.209,0,0,0,1.064-.014l1.718-.991a1.206,1.206,0,0,0,.545-.914l.014-.536a1.443,1.443,0,0,1,1.431-1.4h1.452a1.437,1.437,0,0,1,1.431,1.394l.014.54a1.209,1.209,0,0,0,.544.914l1.718.992a1.208,1.208,0,0,0,1.064.014l.474-.258a1.44,1.44,0,0,1,1.923.543l.725,1.258a1.44,1.44,0,0,1-.5,1.939l-.457.28a1.207,1.207,0,0,0-.519.928v1.984a1.209,1.209,0,0,0,.52.929l.457.28a1.442,1.442,0,0,1,.495,1.939l-.726,1.258a1.439,1.439,0,0,1-1.926.541l-.471-.256a1.209,1.209,0,0,0-1.064.014l-1.718.991a1.206,1.206,0,0,0-.545.914l-.014.536a1.443,1.443,0,0,1-1.431,1.4h-1.452a1.437,1.437,0,0,1-1.431-1.394l-.014-.54a1.209,1.209,0,0,0-.544-.914l-1.718-.992a1.208,1.208,0,0,0-1.064-.014Zm3.88-2.314a4.186,4.186,0,1,0-1.532-5.718A4.186,4.186,0,0,0,145.275,140.07Z"
          transform="translate(70 0)"
          fill="none"
          stroke="#333"
          strokeWidth="1.1"
        />
      </g>
    </g>
  </svg>
);
