import React from "react";

export const Key = ({ width = 70, height = 70 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
  >
    <g
      id="Group_20841"
      data-name="Group 20841"
      transform="translate(-738 -107)"
    >
      <g id="Group_20840" data-name="Group 20840" transform="translate(-200 5)">
        <circle
          id="Ellipse_1145"
          data-name="Ellipse 1145"
          cx="35"
          cy="35"
          r="35"
          transform="translate(938 102)"
          fill="#4b42ae"
          opacity="0.1"
        />
        <circle
          id="Ellipse_1146"
          data-name="Ellipse 1146"
          cx="25"
          cy="25"
          r="25"
          transform="translate(948 112)"
          fill="#4b42ae"
          opacity="0.2"
        />
      </g>
      <g
        id="noun-key-1861222"
        transform="translate(785.443 138.017) rotate(140)"
      >
        <path
          id="Path_25676"
          data-name="Path 25676"
          d="M19.511,9.893a4.668,4.668,0,0,1-4.655-4.4H.275A.275.275,0,0,1,0,5.222V.275a.275.275,0,0,1,.55,0V4.947h4.4V.275a.275.275,0,0,1,.55,0V4.947h9.36a4.667,4.667,0,1,1,4.655,4.946Zm0-.55a4.122,4.122,0,1,0-4.122-4.122A4.118,4.118,0,0,0,19.511,9.344Z"
          transform="translate(0 0)"
          fill="#4b42ae"
          stroke="#4b42ae"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
