export const LOGGED_IN_SUCCESS = "LOGGED_IN_SUCCESS";
export const LOGGED_OUT_SUCCESS = "LOGGED_OUT_SUCCESS";

export const FETCH_SIDEBAR_SUCCESS = "FETCH_SIDEBAR_SUCCESS";

export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const SET_USER_ROLE_SUCCESS = "SET_USER_ROLE_SUCCESS";

export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_LOADING = "FETCH_CLIENTS_LOADING";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
export const FETCH_CLIENT_LOADING = "FETCH_CLIENT_LOADING";
export const FETCH_CLIENT_CATEGORIES_SUCCESS =
  "FETCH_CLIENT_CATEGORIES_SUCCESS";
export const FETCH_CLIENT_USERS_SUCCESS = "FETCH_CLIENT_USERS_SUCCESS";
export const FETCH_CLIENT_BRANDS_SUCCESS = "FETCH_CLIENT_BRANDS_SUCCESS";
export const FETCH_CLIENT_MERCHANTS_SUCCESS = "FETCH_CLIENT_MERCHANTS_SUCCESS";
export const FETCH_CLIENT_VOUCHERS_SUCCESS = "FETCH_CLIENT_VOUCHERS_SUCCESS";
export const FETCH_CLIENT_USER_BRANDS_SUCCESS =
  "FETCH_CLIENT_USER_BRANDS_SUCCESS";
export const FETCH_CLIENT_USER_MERCHANTS_SUCCESS =
  "FETCH_CLIENT_USER_MERCHANTS_SUCCESS";
export const FETCH_CLIENT_USER_VOUCHERS_SUCCESS =
  "FETCH_CLIENT_USER_VOUCHERS_SUCCESS";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const CREATE_CLIENT_CATEGORY_SUCCESS = "CREATE_CLIENT_CATEGORY_SUCCESS";
export const EDIT_CLIENT_CATEGORY_SUCCESS = "EDIT_CLIENT_CATEGORY_SUCCESS";
export const DELETE_CLIENT_CATEGORY_SUCCESS = "DELETE_CLIENT_CATEGORY_SUCCESS";

export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_LOADING = "FETCH_BRANDS_LOADING";
export const FETCH_BRAND_SUCCESS = "FETCH_BRAND_SUCCESS";
export const FETCH_BRAND_LOADING = "FETCH_BRAND_LOADING";
export const FETCH_BRAND_CATEGORIES_SUCCESS = "FETCH_BRAND_CATEGORIES_SUCCESS";
export const FETCH_BRAND_USERS_SUCCESS = "FETCH_BRAND_USERS_SUCCESS";
export const FETCH_BRAND_MERCHANTS_SUCCESS = "FETCH_BRAND_MERCHANTS_SUCCESS";
export const FETCH_BRAND_VOUCHERS_SUCCESS = "FETCH_BRAND_VOUCHERS_SUCCESS";
export const FETCH_BRAND_USER_MERCHANTS_SUCCESS =
  "FETCH_BRAND_USER_MERCHANTS_SUCCESS";
export const FETCH_BRAND_USER_VOUCHERS_SUCCESS =
  "FETCH_BRAND_USER_VOUCHERS_SUCCESS";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const EDIT_BRAND_SUCCESS = "EDIT_BRAND_SUCCESS";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const CREATE_BRAND_CATEGORY_SUCCESS = "CREATE_BRAND_CATEGORY_SUCCESS";
export const EDIT_BRAND_CATEGORY_SUCCESS = "EDIT_BRAND_CATEGORY_SUCCESS";
export const DELETE_BRAND_CATEGORY_SUCCESS = "DELETE_BRAND_CATEGORY_SUCCESS";

export const FETCH_MERCHANTS_SUCCESS = "FETCH_MERCHANTS_SUCCESS";
export const FETCH_MERCHANTS_LOADING = "FETCH_MERCHANTS_LOADING";
export const FETCH_MERCHANT_SUCCESS = "FETCH_MERCHANT_SUCCESS";
export const FETCH_MERCHANT_LOADING = "FETCH_MERCHANT_LOADING";
export const FETCH_MERCHANT_CATEGORIES_SUCCESS = "FETCH_MERCHANT_CATEGORIES";
export const FETCH_MERCHANT_USERS_SUCCESS = "FETCH_MERCHANT_USERS_SUCCESS";
export const FETCH_MERCHANT_USER_VOUCHERS_SUCCESS =
  "FETCH_MERCHANT_USER_VOUCHERS_SUCCESS";
export const FETCH_MERCHANT_VOUCHERS_SUCCESS =
  "FETCH_MERCHANT_VOUCHERS_SUCCESS";
export const FETCH_MERCHANT_AMENITIES_SUCCESS =
  "FETCH_MERCHANT_AMENITIES_SUCCESS";
export const FETCH_MERCHANT_AMENITY_SUCCESS = "FETCH_MERCHANT_AMENITY_SUCCESS";
export const FETCH_MERCHANT_ATTRIBUTES_SUCCESS =
  "FETCH_MERCHANT_ATTRIBUTES_SUCCESS";
export const FETCH_MERCHANT_ATTRIBUTE_SUCCESS =
  "FETCH_MERCHANT_ATTRIBUTE_SUCCESS";
export const SET_MERCHANT_CATEGORIES = "SET_MERCHANT_CATEGORIES";
export const SET_MERCHANT_FORM_DATA = "SET_MERCHANT_FORM_DATA";
export const SET_MERCHANT_ATTRIBUTES = "SET_MERCHANT_ATTRIBUTES";
export const SET_MERCHANT_AMENITIES = "SET_MERCHANT_AMENITIES";
export const DELETE_MERCHANT_SUCCESS = "DELETE_MERCHANT_SUCCESS";
export const CREATE_MERCHANT_CATEGORY_SUCCESS =
  "CREATE_MERCHANT_CATEGORY_SUCCESS";
export const EDIT_MERCHANT_CATEGORY_SUCCESS = "EDIT_MERCHANT_CATEGORY_SUCCESS";
export const DELETE_MERCHANT_CATEGORY_SUCCESS =
  "DELETE_MERCHANT_CATEGORY_SUCCESS";
export const CREATE_MERCHANT_AMENITY_SUCCESS =
  "CREATE_MERCHANT_AMENITY_SUCCESS";
export const EDIT_MERCHANT_AMENITY_SUCCESS = "EDIT_MERCHANT_AMENITY_SUCCESS";
export const DELETE_MERCHANT_AMENITY_SUCCESS =
  "DELETE_MERCHANT_AMENITY_SUCCESS";
export const CREATE_MERCHANT_ATTRIBUTE_SUCCESS =
  "CREATE_MERCHANT_ATTRIBUTE_SUCCESS";
export const EDIT_MERCHANT_ATTRIBUTE_SUCCESS =
  "EDIT_MERCHANT_ATTRIBUTE_SUCCESS";
export const DELETE_MERCHANT_ATTRIBUTE_SUCCESS =
  "DELETE_MERCHANT_ATTRIBUTE_SUCCESS";

export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_LOADING = "FETCH_CUSTOMERS_LOADING";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_LOADING = "FETCH_CUSTOMER_LOADING";
export const FETCH_CUSTOMER_VOUCHERS_SUCCESS =
  "FETCH_CUSTOMER_VOUCHERS_SUCCESS";
export const FETCH_CUSTOMER_PLANS_SUCCESS = "FETCH_CUSTOMER_PLANS_SUCCESS";
export const FETCH_CUSTOMER_NEW_SUCCESS = "FETCH_CUSTOMER_NEW_SUCCESS";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";

export const FETCH_VOUCHERS_SUCCESS = "FETCH_VOUCHERS_SUCCESS";
export const FETCH_VOUCHERS_LOADING = "FETCH_VOUCHERS_LOADING";
export const FETCH_VOUCHER_SUCCESS = "FETCH_VOUCHER_SUCCESS";
export const FETCH_VOUCHER_LOADING = "FETCH_VOUCHER_LOADING";
export const FETCH_REDEEMED_VOUCHERS_SUCCESS =
  "FETCH_REDEEMED_VOUCHERS_SUCCESS";
export const FETCH_REDEEMED_VOUCHERS_LOADING =
  "FETCH_REDEEMED_VOUCHERS_LOADING";
export const FETCH_VOUCHER_CATEGORIES_SUCCESS =
  "FETCH_VOUCHER_CATEGORIES_SUCCESS";

export const FETCH_VOUCHER_SCOPES_SUCCESS = "FETCH_VOUCHER_SCOPES_SUCCESS";
export const FETCH_VOUCHER_SCOPE_SUCCESS = "FETCH_VOUCHER_SCOPE_SUCCESSS";
export const CREATE_VOUCHER_SCOPE_SUCCESS = "CREATE_VOUCHER_SCOPE_SUCCESS";
export const DELETE_VOUCHER_SCOPE_SUCCESS = "DELETE_VOUCHER_SCOPE_SUCCESS";
export const EDIT_VOUCHER_SCOPE_SUCCESS = "EDIT_VOUCHER_SCOPE_SUCCESS";
export const DELETE_VOUCHER_SUCCESS = "DELETE_VOUCHER_SUCCESS";
export const REDEEM_VOUCHER_SUCCESS = "REDEEM_VOUCHER_SUCCESS";
export const APPROVE_VOUCHER_SUCCESS = "APPROVE_VOUCHER_SUCCESS";
export const CREATE_VOUCHER_CATEGORY_SUCCESS =
  "CREATE_VOUCHER_CATEGORY_SUCCESS";
export const EDIT_VOUCHER_CATEGORY_SUCCESS = "EDIT_VOUCHER_CATEGORY_SUCCESS";
export const DELETE_VOUCHER_CATEGORY_SUCCESS =
  "DELETE_VOUCHER_CATEGORY_SUCCESS";

export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_LOADING = "FETCH_PLANS_LOADING";
export const FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS";
export const FETCH_PLAN_LOADING = "FETCH_PLAN_LOADING";
export const FETCH_PLANS_BY_COUNTRY_SUCCESS = "FETCH_PLANS_BY_COUNTRY_SUCCESS";
export const FETCH_PLANS_BY_CITY_SUCCESS = "FETCH_PLANS_BY_CITY_SUCCESS";
export const SET_SUBSCRIPTION_ID = "SET_SUBSCRIPTION_ID";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const EDIT_PLAN_SUCCESS = "EDIT_PLAN_SUCCESS";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const SUBSCRIBE_PLAN_SUCCESS = "SUBSCRIBE_PLAN_SUCCESS";

export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_LOADING = "FETCH_SUBSCRIPTIONS_LOADING";

export const FETCH_COUNTRIES_INFO_LOADING = "FETCH_COUNTRIES_INFO_LOADING";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_INFO_SUCCESS = "FETCH_COUNTRIES_INFO_SUCCESS";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";

export const FETCH_ACTIVITY_LOGS_SUCCESS = "FETCH_ACTIVITY_LOGS_SUCCESS";
export const FETCH_ACTIVITY_LOG_SUCCESS = "FETCH_ACTIVITY_LOG_SUCCESS";

export const CREATE_CITY_SUCCESS = "CREATE_CITY_SUCCESS";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";

export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";

export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
