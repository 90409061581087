import styled from "styled-components";

export const CustomDatePicker = styled.div`
  position: relative;
`;

export const Input = styled.input``;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;
