import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInView } from "react-intersection-observer";
import Select from "react-select";
import toast from "react-hot-toast";

import { clientSchema } from "../../schema";
import {
  Input,
  Button,
  Map,
  UploadField,
  ErrorMessage,
  Card,
  Grid,
  MainContent,
  Buttons,
} from "@vms-admin/components";
import {
  fetchCountries,
  fetchCountryInfo,
  fetchClientCategories,
  fetchClient,
  editClient,
} from "../../redux/actions";
import {
  getCountryOptions,
  getCityOptions,
  getCategoryOptions,
} from "@vms-admin/utils";
import { dismissToast, somethignWentWrong } from "../../helpers";
import EditClientLoader from "./edit-client-loader";

export const EditClient = () => {
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const { id } = useParams();
  const { ref, inView } = useInView();
  const history = useHistory();
  const dispatch = useDispatch();
  const clientData = useSelector(({ clients }) => clients);
  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(clientSchema),
  });

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
    },
    [setValue, dispatch]
  );

  const handleEditClient = useCallback(
    (data) => {
      dismissToast();
      dispatch(editClient(data, clientData, id, address, lat, lng))
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => history.push("/client-management"), [2000]);
            toast.success(`${data.name} has been updated Successfully`);
          } else if (res.status === 409) {
            toast.error("User already exist");
          } else if (res.status === 401) {
            history.push("/");
          } else {
            somethignWentWrong();
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, address, lat, lng, history, clientData, id]
  );

  useEffect(() => {
    dispatch(fetchCountries());

    clientData?.client?.country?.id &&
      dispatch(fetchCountryInfo(clientData?.client?.country?.id));
    dispatch(fetchClientCategories());
  }, [dispatch, clientData?.client]);

  useEffect(() => dispatch(fetchClient(id)), [dispatch, id]);

  useEffect(() => {
    setValue("name", clientData?.client?.client_name);
    setValue("email", clientData?.client?.email);
    setValue("mobileNumber", clientData?.client?.telephone);
    setValue("countryName", {
      value: clientData?.client?.country,
      label: clientData?.client?.country,
    });
    setValue("category", {
      value: clientData?.client?.client_category?.category_name,
      label: clientData?.client?.client_category?.category_name,
    });
    setValue("cityName", {
      value: clientData?.client?.city,
      label: clientData?.client?.city,
    });
    setValue("poBox", clientData?.client.po_box);
    setValue("address", clientData?.client.address);
  }, [clientData?.client, setValue]);

  useEffect(
    () =>
      !clientData &&
      toast.error(
        "It looks like there is an issue, please try again in few minutes "
      ),
    [clientData]
  );

  return (
    <MainContent>
      <form onSubmit={handleSubmit(handleEditClient)} noValidate>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Client Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Link to="/client-management" className="btn btn-secondary">
                  Back
                </Link>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        {clientData.loadingClient ? (
          <EditClientLoader />
        ) : (
          <div className="cards_wrapper">
            <Card title="Edit Client">
              <Grid columns={2}>
                <Input
                  label="Client Name"
                  type="text"
                  placeholder="Client Name"
                  name="name"
                  register={register}
                  errors={errors}
                />
                <div className="input_holder">
                  <label>Client Category</label>
                  <Controller
                    name="category"
                    errors={errors}
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        options={getCategoryOptions(clientData?.categories)}
                        placeholder="Select Category"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["category"] && (
                    <ErrorMessage>Client Category is Required</ErrorMessage>
                  )}
                </div>
              </Grid>
              <Grid columns={2}>
                <Input
                  label="Email Address"
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  register={register}
                  errors={errors}
                />
                <Input
                  label="Client Contact Number"
                  type="tel"
                  placeholder="Client Contact Number"
                  name="mobileNumber"
                  register={register}
                  errors={errors}
                />
              </Grid>
              <UploadField
                label="Client Logo"
                name="logo"
                image={clientData?.client?.image}
                errors={errors}
                register={register}
              />
            </Card>
            <Card title="Location">
              <Grid columns={3}>
                <div className="input_holder">
                  <label>Country Name</label>
                  <Controller
                    name="countryName"
                    errors={errors}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        menuPortalTarget={document.body}
                        options={getCountryOptions(
                          countryManagementState.countries
                        )}
                        placeholder="Select Country"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        value={value ? value : ""}
                      />
                    )}
                  />
                  {errors && errors["countryName"] && (
                    <ErrorMessage>Country is Required</ErrorMessage>
                  )}
                </div>
                <div className="input_holder">
                  <label>City Name</label>
                  <Controller
                    name="cityName"
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPortalTarget={document.body}
                        {...field}
                        options={getCityOptions(
                          countryManagementState?.countryInfo?.cities
                        )}
                        isLoading={countryManagementState?.countryInfoLoading}
                        loadingMessage={() => "Loading..."}
                        placeholder="Select City"
                        className="kmmrce-select-container"
                        classNamePrefix="kmmrce-select"
                      />
                    )}
                  />
                  {errors && errors["cityName"] && (
                    <ErrorMessage>City is Required</ErrorMessage>
                  )}
                </div>
                <Input
                  label="Client P.O. Box"
                  type="text"
                  placeholder="Client P.O. Box"
                  name="poBox"
                  register={register}
                  errors={errors}
                />
              </Grid>
              <Map
                addressInfo={(address, lat, lng) => {
                  setAddress(address);
                  setLat(lat);
                  setLng(lng);
                }}
                address={clientData?.client?.address}
                userLat={Number(clientData?.client?.lat)}
                userLng={Number(clientData?.client?.long)}
              />
            </Card>
          </div>
        )}
      </form>
    </MainContent>
  );
};

export default EditClient;
