import React, { useEffect, useCallback } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

import { getCountryOptions, getCityOptions } from "@vms-admin/utils";
import {
  MainContent,
  Card,
  Input,
  Grid,
  ErrorMessage,
  Button,
  ChangePasswordForm,
} from "@vms-admin/components";
import * as Styled from "./account-settings.style";
import { fetchCountries, fetchCountryInfo } from "../../redux/actions";

export const AccountSettings = () => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
    },
    [setValue, dispatch]
  );

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  useEffect(() => {
    setValue("fullName", userInfo[`${userInfo.role}_name`]);
    setValue("emailAddress", userInfo?.email);
    setValue("phone", userInfo?.telephone);
    setValue("address", userInfo?.address);
    setValue("countryName", {
      label: userInfo?.country?.country_name,
      value: userInfo?.country?.country_code,
    });
    setValue("cityName", {
      label: userInfo?.city?.city_name,
      value: userInfo?.city?.city_code,
    });
  }, [userInfo, setValue]);

  return (
    <MainContent>
      <div className="container_mobile">
        <div className="page-header">
          <h1>Account Settings</h1>
          <Button className="btn btn-primary">Save Changes</Button>
        </div>
      </div>
      <Styled.AccountSettings>
        <Grid>
          <Tabs>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Security</Tab>
            </TabList>
            <TabPanel>
              <Card title="Profile Overview" style={{ marginBottom: 20 }}>
                <div>
                  <h5 style={{ marginBottom: 10 }}>Profile Photo</h5>
                  <Styled.PhotoWrapper>
                    <Styled.Image
                      src={userInfo?.image}
                      alt={userInfo?.client_name}
                    />
                    <Button variant="tertiary">Change</Button>
                    <Button variant="danger">Remove</Button>
                  </Styled.PhotoWrapper>
                </div>
                <Grid columns={3}>
                  <Input
                    type="text"
                    label="Full Name"
                    placeholder="Full Name"
                    name="fullName"
                    register={register}
                  />
                  <Input
                    type="email"
                    label="Email Address"
                    placeholder="Email Address"
                    name="emailAddress"
                    register={register}
                  />
                  <Input
                    type="tel"
                    label="Phone"
                    placeholder="Phone"
                    name="phone"
                    register={register}
                  />
                </Grid>
              </Card>
              <Card title="Address">
                <Input
                  type="text"
                  label="Address"
                  placeholder="Address"
                  name="address"
                  register={register}
                />

                <Grid column={2}>
                  <div className="input_holder">
                    <label>Country Name</label>
                    <Controller
                      name="countryName"
                      errors={errors}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          menuPortalTarget={document.body}
                          options={getCountryOptions(
                            countryManagementState.countries
                          )}
                          placeholder="Select Country"
                          className="kmmrce-select-container"
                          classNamePrefix="kmmrce-select"
                          onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}
                          value={value ? value : ""}
                        />
                      )}
                    />
                    {errors && errors["countryName"] && (
                      <ErrorMessage text="Country is Required" />
                    )}
                  </div>
                  <div className="input_holder">
                    <label>City Name</label>
                    <Controller
                      name="cityName"
                      control={control}
                      render={({ field }) => (
                        <Select
                          menuPortalTarget={document.body}
                          {...field}
                          options={getCityOptions(
                            countryManagementState?.countryInfo?.cities
                          )}
                          isLoading={countryManagementState?.countryInfoLoading}
                          loadingMessage={() => "Loading..."}
                          placeholder="Select City"
                          className="kmmrce-select-container"
                          classNamePrefix="kmmrce-select"
                        />
                      )}
                    />
                    {errors && errors["cityName"] && (
                      <ErrorMessage text="City is Required" />
                    )}
                  </div>
                </Grid>
              </Card>
            </TabPanel>
            <TabPanel>
              {/* <Card title="Password"> */}
              {/* <div className="flex justify"> */}
              <ChangePasswordForm />

              {/* <Note>
                    <h3>Note</h3>
                    <p>
                      Your password need to meet the following requirements:
                    </p>
                    <ul>
                      <li> Minimum 8 characters</li>
                      <li>One special character</li>
                      <li>One number</li>
                      <li>One uppercase letter</li>
                    </ul>
                  </Note> */}
              {/* </div> */}
              {/* </Card> */}
            </TabPanel>
          </Tabs>
          <Card title="Login activity">
            <ul>
              <li style={{ display: "flex", marginBottom: 32 }}>
                <div style={{ marginRight: 8 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.505"
                    height="17.791"
                    viewBox="0 0 18.505 17.791"
                  >
                    <g id="COMPUTER" transform="translate(-274.656 -128.55)">
                      <path
                        id="Path_25638"
                        data-name="Path 25638"
                        d="M365.67,410.48a.245.245,0,0,0-.19.092.366.366,0,0,0,0,.455.245.245,0,0,0,.19.092h5.867a.245.245,0,0,0,.19-.092.366.366,0,0,0,0-.455.245.245,0,0,0-.19-.092Z"
                        transform="translate(-84.694 -265.027)"
                        fill="#333"
                        stroke="#333"
                        strokeWidth="0.5"
                        fillRule="evenodd"
                      />
                      <path
                        id="Path_25639"
                        data-name="Path 25639"
                        d="M275.388,128.8a.32.32,0,0,0-.319.319v13.892h0a.32.32,0,0,0,.319.319h17.366a.319.319,0,0,0,.319-.319V129.119h0a.319.319,0,0,0-.319-.319Zm.319.639h16.727v13.253H275.707Z"
                        transform="translate(-0.162)"
                        fill="#333"
                        stroke="#333"
                        strokeWidth="0.5"
                        fillRule="evenodd"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h3
                    style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}
                  >
                    Macbook Pro
                  </h3>
                  <p style={{ fontSize: 14, color: "#acacac" }}>
                    London, UK - Oct 23 at 1:15 AM
                  </p>
                </div>
              </li>

              <li style={{ display: "flex", marginBottom: 32 }}>
                <div style={{ marginRight: 8 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.5"
                    height="17.791"
                    viewBox="0 0 12.5 17.791"
                  >
                    <g
                      id="Group_20660"
                      data-name="Group 20660"
                      transform="translate(-1412.018 -274.75)"
                    >
                      <path
                        id="Path_25640"
                        data-name="Path 25640"
                        d="M275.375,128.8a.288.288,0,0,0-.307.264v11.473h0a.288.288,0,0,0,.307.264h16.678a.288.288,0,0,0,.307-.264V129.064h0a.288.288,0,0,0-.307-.264Zm.307.527h16.064v10.945H275.682Z"
                        transform="translate(1553.068 -0.068) rotate(90)"
                        fill="#333"
                        stroke="#333"
                        strokeWidth="0.5"
                        fillRule="evenodd"
                      />
                      <path
                        id="Path_25641"
                        data-name="Path 25641"
                        d="M365.569,410.48c-.044,0-.087.032-.119.092a.548.548,0,0,0,0,.455c.032.06.074.093.119.092h3.664a.142.142,0,0,0,.119-.092.548.548,0,0,0,0-.455.142.142,0,0,0-.119-.092Z"
                        transform="translate(1050.868 -121.827)"
                        fill="#333"
                        stroke="#333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        strokeWidth="0.5"
                        fillRule="evenodd"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h3
                    style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}
                  >
                    iPhone 12
                  </h3>
                  <p style={{ fontSize: 14, color: "#acacac" }}>
                    London, UK - Oct 23 at 1:15 AM
                  </p>
                </div>
              </li>

              <li style={{ display: "flex", marginBottom: 32 }}>
                <div style={{ marginRight: 8 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.078"
                    height="16.116"
                    viewBox="0 0 14.078 16.116"
                  >
                    <g id="KEY" transform="translate(-226.288 -136.01)">
                      <path
                        id="Path_25642"
                        data-name="Path 25642"
                        d="M236.953,151.248a.3.3,0,1,0,.423.423l2.495-2.507-7.368-7.352a3.338,3.338,0,0,0-2.555-5.452h-.03a3.266,3.266,0,0,0-2.327.961,3.335,3.335,0,0,0,2.384,5.671,3.281,3.281,0,0,0,2.1-.76l4.776,4.77-1.909,1.909h0a.3.3,0,1,0,.408.426l1.909-1.909,1.759,1.735Zm-8.917-9.658a2.72,2.72,0,0,1-.021-3.834,2.649,2.649,0,0,1,1.915-.787,2.735,2.735,0,0,1,2.024,4.563.3.3,0,0,0-.162.162,2.72,2.72,0,0,1-3.756-.105Z"
                        transform="translate(0 0)"
                        fill="#333"
                        stroke="#333"
                        strokeWidth="0.7"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h3
                    style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}
                  >
                    Password Changed
                  </h3>
                  <p style={{ fontSize: 14, color: "#acacac" }}>2 hours ago</p>
                </div>
              </li>
            </ul>
            <Button variant="purple">Contact Support</Button>
          </Card>
        </Grid>
      </Styled.AccountSettings>
    </MainContent>
  );
};

export default AccountSettings;
