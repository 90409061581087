import { React, forwardRef } from "react";

import { Calendar } from "@vms-admin/icons";
import * as Styled from "./custom-datepicker.style";

export const CustomDatePicker = forwardRef(({ value, onClick, label }, ref) => (
  <Styled.CustomDatePicker onClick={onClick} className="input_holder">
    {label}
    <div
      style={{
        position: "relative",
      }}
    >
      <Styled.Input value={value} ref={ref} />
      <Styled.Icon>
        <Calendar />
      </Styled.Icon>
    </div>
  </Styled.CustomDatePicker>
));

export default CustomDatePicker;
