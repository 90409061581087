import React from "react";

export const KMMRCELogo = ({ width = 34, height = 34 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 34 34"
  >
    <g
      id="Group_3801"
      data-name="Group 3801"
      transform="translate(-49.688 -25)"
    >
      <ellipse
        id="Ellipse_191"
        data-name="Ellipse 191"
        cx="6.353"
        cy="6.375"
        rx="6.353"
        ry="6.375"
        transform="translate(61.334 35.61)"
        fill="#333"
      />
      <ellipse
        id="Ellipse_192"
        data-name="Ellipse 192"
        cx="6.353"
        cy="6.375"
        rx="6.353"
        ry="6.375"
        transform="translate(70.864 25)"
        fill="#333"
      />
      <ellipse
        id="Ellipse_193"
        data-name="Ellipse 193"
        cx="6.522"
        cy="6.159"
        rx="6.522"
        ry="6.159"
        transform="translate(70.644 46.385)"
        fill="#333"
      />
      <rect
        id="Rectangle_1013"
        data-name="Rectangle 1013"
        width="10.447"
        height="34"
        transform="translate(49.688 25)"
        fill="#333"
      />
    </g>
  </svg>
);
