import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import {
  TableCard,
  Button,
  Modal,
  Pagination,
  ToolbarButton,
  Buttons,
  TableCardLoader,
} from "@vms-admin/components";
import { active, dismissToast, formatDate, inActive } from "../../helpers";
import { deletePlan, subscribe, fetchPlans } from "../../redux/actions";
import toast from "react-hot-toast";
import { usePagination } from "../../hooks/usePagination";

export const PlansTable = ({ subscribePlans, plans, plansLoading }) => {
  const [target, setTarget] = useState({ name: "", id: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userRole = useSelector(({ auth }) => auth.role);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    goToNextPage,
    goToPrevPage,
    pagesArray,
    canGoNext,
    canGoPrev,
    currentPage,
    pageDecrementBtn,
    pageIncementBtn,
  } = usePagination(plans?.pages);

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const handleDeletePlan = useCallback(
    (id) => {
      dismissToast();
      dispatch(deletePlan(id)).then((res) => {
        if (res.status === 200) {
          toggleModal();
          dispatch(fetchPlans(currentPage));
          toast.success(`Plan has been deleted Successfully`);
        }
      });
    },
    [dispatch, toggleModal, currentPage]
  );

  const handleSubscribe = useCallback(
    (id) =>
      dispatch(subscribe(id))
        .then((data) => {
          if (data) {
            history.push("/subscription-payment");
            localStorage.setItem("subscription_id", data.subscription_id);
          }
        })
        .catch((error) => console.log("error", error)),
    [dispatch, history]
  );

  const subscribedPlansIds = useMemo(
    () => subscribePlans?.map((plan) => plan.id),
    [subscribePlans]
  );

  useEffect(() => dispatch(fetchPlans(currentPage)), [dispatch, currentPage]);

  return (
    <>
      <TableCard
        title="Subscription Plans"
        btnText="Create New Subscription"
        btnUrl="/create-plan"
        hasLink={!userRole.is_customer}
      >
        {!plans?.length && !plansLoading && (
          <tbody>
            <tr>
              <td className="empty_list_message">No Plans found</td>
            </tr>
          </tbody>
        )}
        {!plansLoading ? (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>Plan Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {plans?.map(
                ({ id, plan_name, start_date, end_date, is_active }, index) => (
                  <tr
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    key={id}
                  >
                    <td>{index + 1}</td>
                    <td>{plan_name}</td>
                    <td>{formatDate(start_date)}</td>
                    <td>{formatDate(end_date)}</td>
                    <td>
                      {is_active ? active("Active") : inActive("In Active")}
                    </td>
                    {!userRole.is_customer && (
                      <td>
                        <ToolbarButton
                          btns={[
                            { btnUrl: `/plan/${id}`, btnText: "View" },
                            {
                              btnUrl: `/edit-plan/${id}`,
                              btnText: "Edit",
                            },
                            {
                              handleClick: () => {
                                toggleModal();
                                setTarget({ id, name: plan_name });
                              },
                              btnText: "Delete",
                            },
                          ]}
                        />
                      </td>
                    )}

                    {userRole.is_customer && (
                      <td>
                        {subscribedPlansIds?.includes(id) ? (
                          <span className="btn btn-tertiary">Subscribed</span>
                        ) : (
                          <Button
                            variant="tertiary"
                            handleClick={() => handleSubscribe(id)}
                          >
                            Subscribe
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </>
        ) : (
          <TableCardLoader />
        )}
      </TableCard>
      {plans?.pages > 1 && (
        <Pagination
          canGoNext={canGoNext}
          canGoPrev={canGoPrev}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          pagesArray={pagesArray}
          pageDecrementBtn={pageDecrementBtn}
          pageIncementBtn={pageIncementBtn}
        />
      )}
      {isModalOpen && (
        <Modal title="Delete">
          Are you sure you want to delete {target.name}?
          <Buttons>
            <Button variant="secondary" handleClick={toggleModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              handleClick={() => handleDeletePlan(target.id)}
            >
              Confirm
            </Button>
          </Buttons>
        </Modal>
      )}
    </>
  );
};

export default PlansTable;
