import React from "react";
import { switchAnimation } from "../../utils/animations";

export const Switch = ({
  label,
  icon,
  name,
  handleChange,
  register,
  defaultValue,
  asRadio,
  value,
}) => (
  <div className="kmmrce-switch">
    <label className="kmmrce-switch__label">
      {icon && <img className="kmmrce-switch__icon" src={icon} alt={label} />}
      {label}
    </label>
    <div className="kmmrce-switch__wrapper">
      <input
        className="kmmrce-switch__input"
        type={asRadio ? `radio` : `checkbox`}
        name={name}
        value={value}
        {...register(name, { onChange: handleChange })}
        defaultChecked={defaultValue}
      />
      <div className="kmmrce-switch__handler-wrapper">
        <div
          className="kmmrce-switch__handler "
          transition={switchAnimation}
        ></div>
      </div>
    </div>
  </div>
);

export default Switch;
