import { Grid, Card, StatsCard } from "@vms-admin/components";
import { Button } from "@vms-admin/components";
import { useHistory } from "react-router";

function ClientDashboard({ operational, revenue }) {
  const history = useHistory();

  return (
    <Grid columns={3}>
      <div>
        <StatsCard
          title="Total Brands"
          value={operational?.total_brands}
          decimals={0}
          hasDetails={false}
        ></StatsCard>
        <StatsCard
          title="Total Merchants"
          value={operational?.total_merchants}
          decimals={0}
          hasDetails={false}
          className="mt20"
        ></StatsCard>
        <StatsCard
          title="Total Redemptions"
          value={operational?.total_redemptions}
          decimals={0}
          hasDetails={false}
          className="mt20"
        ></StatsCard>
      </div>
      <div>
        <Card
          title="Latest Redemptions"
          className="mb20"
          footerContent={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="tertiary"
                handleClick={() => history.push("/voucher-redemption")}
              >
                View All
              </Button>
            </div>
          }
        >
          <div className="pt-[20px] height_full">
            {!revenue?.latest_redemptions?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "#BDBDBD",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <h3>No Redemptions Yet</h3>
              </div>
            ) : (
              revenue?.latest_redemptions?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                    marginBottom:
                      index + 1 === revenue?.latest_redemptions?.length
                        ? ""
                        : "30px",
                    borderBottom:
                      index + 1 !== revenue?.latest_redemptions?.length
                        ? "1px solid #E5E5E5"
                        : "",
                  }}
                >
                  <h5>{item.name}</h5>
                  <h5>{item.count}</h5>
                </div>
              ))
            )}
          </div>
        </Card>
        <Card
          title="Most Performing Brands"
          footerContent={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="tertiary"
                handleClick={() => history.push("/brand-management")}
              >
                View All
              </Button>
            </div>
          }
        >
          <div className="pt-[20px] height_full">
            {!revenue?.most_performing_brands?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "#BDBDBD",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <h3>No Brands Yet</h3>
              </div>
            ) : (
              revenue?.most_performing_brands?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                    marginBottom:
                      index + 1 === revenue?.most_performing_brands?.length
                        ? ""
                        : "30px",
                    borderBottom:
                      index + 1 !== revenue?.most_performing_brands?.length
                        ? "1px solid #E5E5E5"
                        : "",
                  }}
                >
                  <h5>{item.brand_name}</h5>
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
      <div>
        <Card
          title="Most Redeemed Vouchers"
          footerContent={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="tertiary"
                handleClick={() => history.push("/vouchers")}
              >
                View All
              </Button>
            </div>
          }
        >
          <div className="pt-[20px] height_full">
            {!revenue?.most_active_vouchers?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "#BDBDBD",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <h3>No Vouchers Redeemed Yet</h3>
              </div>
            ) : (
              revenue?.most_active_vouchers?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                    marginBottom:
                      index + 1 === revenue?.most_active_vouchers?.length
                        ? ""
                        : "30px",
                    borderBottom:
                      index + 1 !== revenue?.most_active_vouchers?.length
                        ? "1px solid #E5E5E5"
                        : "",
                  }}
                >
                  <h5>{item.name}</h5>
                  <h5>{item.count}</h5>
                </div>
              ))
            )}
          </div>
        </Card>
        <Card
          className="mt20"
          title="Most Performing Merchants"
          footerContent={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="tertiary"
                handleClick={() => history.push("/merchant-management")}
              >
                View All
              </Button>
            </div>
          }
        >
          <div className="pt-[20px] height_full">
            {!revenue?.most_performing_merchants?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "#BDBDBD",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <h3>No Merchants Yet</h3>
              </div>
            ) : (
              revenue?.most_performing_merchants?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                    marginBottom:
                      index + 1 === revenue?.most_performing_merchants?.length
                        ? ""
                        : "30px",
                    borderBottom:
                      index + 1 !== revenue?.most_performing_merchants?.length
                        ? "1px solid #E5E5E5"
                        : "",
                  }}
                >
                  <h5>{item.merchant_name}</h5>
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
    </Grid>
  );
}

export default ClientDashboard;
