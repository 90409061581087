import { FETCH_DASHBOARD_SUCCESS } from "../actions/actions.constants";

const initialState = {
  operational: {},
  revenue: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        operational: action.operational,
        revenue: action.revenue,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
