export const getCountryOptions = (countries) =>
  countries?.map(({ iso3, country, id }) => ({
    value: iso3,
    label: country,
    id: country,
  }));

export const getCityOptions = (cities) =>
  cities?.map((city) => ({
    value: city,
    label: city,
    id: city,
  }));

export const getCategoryOptions = (categories) =>
  categories?.map(({ id, category_name }) => ({
    value: category_name,
    label: category_name,
    id,
  }));

export const getClientOptions = (clients) =>
  clients?.map(({ client_name, id }) => ({
    value: client_name,
    label: client_name,
    id,
  }));

export const getBrandOptions = (brands) =>
  brands?.map(({ brand_name, id }) => ({
    value: brand_name,
    label: brand_name,
    id,
  }));

export const getMerchantOptions = (merchants) =>
  merchants?.map(({ merchant_name, id }) => ({
    value: merchant_name,
    label: merchant_name,
    id,
  }));

export const getCurrencyOptions = (currencies) =>
  currencies?.map((currency) => ({
    value: currency,
    label: currency,
    id: currency,
  }));

export const getPlansOptions = (plans) =>
  plans?.map(({ plan_name, id }) => ({
    value: plan_name,
    label: plan_name,
    id,
  }));

export const getVoucherScopesOptions = (scopes) =>
  scopes?.map(({ voucher_scope_name, id }) => ({
    value: voucher_scope_name,
    label: voucher_scope_name,
    id,
  }));
