import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { CustomDatePicker, ErrorMessage } from "@vms-admin/components";

export const Datepicker = ({ label, name, errorMessage, control, errors }) => {
  return (
    <div className="input_holder">
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            onChange={onChange}
            selected={value}
            customInput={<CustomDatePicker />}
          />
        )}
      />
      {errors && errors[name] && <ErrorMessage text={errorMessage} />}
    </div>
  );
};

export default Datepicker;
