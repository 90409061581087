import React from "react";

export const Profile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.053"
    height="20"
    viewBox="0 0 20.053 20"
  >
    <g id="noun_support_744331" transform="translate(-12.4 -12.4)">
      <path
        id="Path_1897"
        data-name="Path 1897"
        d="M22.427,12.4A10,10,0,1,0,32.453,22.427,10.066,10.066,0,0,0,22.427,12.4Zm0,.987a9.064,9.064,0,0,1,6,2.293l-3.2,3.147a4.543,4.543,0,0,0-5.653.053L16.4,15.68A9.051,9.051,0,0,1,22.427,13.387ZM26,22.373A3.547,3.547,0,1,1,22.427,18.8,3.591,3.591,0,0,1,26,22.373Zm-12.587.053a8.972,8.972,0,0,1,2.32-6.027l3.173,3.2a4.31,4.31,0,0,0-1.013,2.827,4.547,4.547,0,0,0,.987,2.827l-3.2,3.173A9.093,9.093,0,0,1,13.413,22.427Zm9.013,9.013a8.885,8.885,0,0,1-6.053-2.347l3.2-3.173a4.366,4.366,0,0,0,2.853,1.04,4.416,4.416,0,0,0,2.827-1.013l3.173,3.227A8.939,8.939,0,0,1,22.427,31.44Zm6.72-2.987-3.173-3.227A4.415,4.415,0,0,0,26.987,22.4a4.508,4.508,0,0,0-1.013-2.88l3.2-3.147a8.972,8.972,0,0,1,2.32,6.027A9.143,9.143,0,0,1,29.147,28.453Z"
      />
    </g>
  </svg>
);
