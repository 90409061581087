import React from "react";

export const Voucher = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_20856"
      data-name="Group 20856"
      transform="translate(-1663 -541)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(1663 541)"
        fill="rgba(255,255,255,0)"
      />
      <g id="noun-voucher-4544979" transform="translate(1489.466 394.667)">
        <path
          id="Path_25692"
          data-name="Path 25692"
          d="M306.01,255.36a.416.416,0,0,0-.416.416v1.941a.416.416,0,1,0,.832,0v-1.941A.416.416,0,0,0,306.01,255.36Z"
          transform="translate(-122.052 -98.09)"
          fill="#333"
          stroke="#333"
          strokeWidth="0.3"
        />
        <path
          id="Path_25693"
          data-name="Path 25693"
          d="M194.632,156.244a1.464,1.464,0,0,0,1.148-1.364v-1.331a2.112,2.112,0,0,0-2.113-2.113H178.4a2.112,2.112,0,0,0-2.113,2.113v1.331a1.458,1.458,0,0,0,1.153,1.364,2.44,2.44,0,0,1,0,4.785,1.459,1.459,0,0,0-1.148,1.364v1.331a2.112,2.112,0,0,0,2.113,2.113h15.27a2.113,2.113,0,0,0,2.113-2.113v-1.331a1.464,1.464,0,0,0-1.153-1.364,2.44,2.44,0,0,1,0-4.785Zm-2.772,2.39h0a3.2,3.2,0,0,0,2.584,3.2.649.649,0,0,1,.527.555v1.331A1.281,1.281,0,0,1,193.689,165h-9.315v-2.146a.416.416,0,1,0-.832,0v2.168H178.4a1.281,1.281,0,0,1-1.281-1.281v-1.331a.649.649,0,0,1,.527-.555,3.271,3.271,0,0,0,0-6.4.649.649,0,0,1-.527-.555v-1.331a1.281,1.281,0,0,1,1.281-1.281h5.146v2.168h0a.416.416,0,1,0,.832,0v-2.179h9.3a1.281,1.281,0,0,1,1.281,1.281v1.331a.649.649,0,0,1-.527.555,3.2,3.2,0,0,0-2.584,3.21Z"
          transform="translate(0)"
          fill="#333"
          stroke="#333"
          strokeWidth="0.3"
        />
      </g>
    </g>
  </svg>
);
