import { React } from "react";
import { Link } from "react-router-dom";

import { Header } from "@vms-admin/components";
import { Envelope, LeftArrow } from "@vms-admin/icons";
import * as Styled from "./confirmation.style";

export const EmailSent = () => (
  <>
    <Header />
    <Styled.Wrapper>
      <Styled.Icon>
        <Envelope />
      </Styled.Icon>
      <Styled.Title>Check your email</Styled.Title>
      <Styled.Text>
        We sent a password reset link to <strong>narimanadam7@gmail.com</strong>
      </Styled.Text>
      <Link className="btn btn-primary" style={{ width: "100%" }} to="/">
        Open mail app
      </Link>
      <Styled.Text>
        Didn't receive an email? <span>Click to resend</span>{" "}
      </Styled.Text>
      <Link to="/">
        <Styled.Link>
          <LeftArrow />
          <Styled.LinkText>Back to login</Styled.LinkText>
        </Styled.Link>
      </Link>
    </Styled.Wrapper>
  </>
);

export default EmailSent;
