import React from "react";
import Skeleton from "react-loading-skeleton";

import { TableCard } from "@vms-admin/components";

const VoucherDetailsLoader = () => (
  <div className="cards_wrapper">
    <TableCard
      title={
        <Skeleton
          height={25}
          width={300}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      }
    >
      <tbody>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={70} width={70} borderRadius="50%" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
      </tbody>
    </TableCard>
    <TableCard
      title={
        <Skeleton
          height={25}
          width={300}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      }
    >
      <tbody>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height={15} width={100} />
          </td>
          <td>
            <Skeleton height={15} width={200} />
          </td>
        </tr>
      </tbody>
    </TableCard>
    <TableCard
      title={
        <Skeleton
          height={25}
          width={300}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      }
    >
      <tbody>
        <tr>
          <td>
            <div className="flex">
              {Array.from({ length: 5 }, (_, i) => (
                <span className="label">
                  <Skeleton height={24} width={80} />
                </span>
              ))}
            </div>
          </td>
        </tr>
      </tbody>
    </TableCard>
  </div>
);

export default VoucherDetailsLoader;
