import React from "react";

import * as Styled from "./grid.style";

export const Grid = ({ children, columns, ...rest }) => (
  <Styled.Grid className={`grid grid-cols-${columns}`} {...rest}>
    {children}
  </Styled.Grid>
);

export default Grid;
