import { Grid, Card, StatsCard } from "@vms-admin/components";
import { Button } from "@vms-admin/components";
import Chart from "react-apexcharts";
import { useHistory } from "react-router";

function MerchantDashboard({ operational, revenue }) {
  const history = useHistory();

  const overviewChartOptions = {
    colors: ["#4B42AD", "#3CD17F", "#D7D7Da"],
    legend: {
      position: "bottom",
      horizontalAlign: "left",
    },
    chart: {
      type: "donut",
    },
    labels: ["Total", "Active", "Pending Approval"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const overviewChartData = [
    operational?.total_vouchers || 0,
    operational?.active_vouchers || 0,
    operational?.vouchers_pending_approval || 0,
  ];

  return (
    <Grid columns={2}>
      <div className="height_full">
        <StatsCard
          title="Total Redemptions"
          value={operational?.total_redemptions}
          decimals={0}
          hasDetails={false}
        ></StatsCard>
        <Card title="Vouchers" className="mt20 height_full">
          <div
            className="height_full"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Chart
              options={overviewChartOptions}
              series={overviewChartData}
              height={370}
              width="100%"
              type="donut"
            />
          </div>
        </Card>
      </div>

      <div className="height_full">
        <Card
          title="Most Redeemed Vouchers"
          footerContent={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="tertiary"
                handleClick={() => history.push("/vouchers")}
              >
                View All
              </Button>
            </div>
          }
        >
          <div className="pt-[20px] height_full">
            {!revenue?.most_active_vouchers?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "#BDBDBD",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <h3>No Vouchers Redeemed Yet</h3>
              </div>
            ) : (
              revenue?.most_active_vouchers?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                    marginBottom:
                      index + 1 === revenue?.most_active_vouchers?.length
                        ? ""
                        : "30px",
                    borderBottom:
                      index + 1 !== revenue?.most_active_vouchers?.length
                        ? "1px solid #E5E5E5"
                        : "",
                  }}
                >
                  <h5>{item.name}</h5>
                  <h5>{item.count}</h5>
                </div>
              ))
            )}
          </div>
        </Card>
        <Card
          title="Latest Redemptions"
          className="mt20"
          footerContent={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="tertiary"
                handleClick={() => history.push("/voucher-redemption")}
              >
                View All
              </Button>
            </div>
          }
        >
          <div className="pt-[20px] height_full">
            {!revenue?.latest_redemptions?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "#BDBDBD",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <h3>No Redemptions Yet</h3>
              </div>
            ) : (
              revenue?.latest_redemptions?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                    marginBottom:
                      index + 1 === revenue?.latest_redemptions?.length
                        ? ""
                        : "30px",
                    borderBottom:
                      index + 1 !== revenue?.latest_redemptions?.length
                        ? "1px solid #E5E5E5"
                        : "",
                  }}
                >
                  <h5>{item.name}</h5>
                  <h5>{item.count}</h5>
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
    </Grid>
  );
}

export default MerchantDashboard;
