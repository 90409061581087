import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MerchantsTable, MainContent } from "@vms-admin/components";
import {
  fetchMerchants,
  fetchClientMerchants,
  fetchBrandMerchants,
  fetchClientUserMerchants,
  fetchBrandUserMerchants,
} from "../../redux/actions";

export const MerchantManagement = () => {
  const plans = useSelector(({ plans }) => plans.plans);
  const [userMerchants, setUserMerchants] = useState();
  const merchants = useSelector(({ merchant }) => merchant);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const userRole = useSelector(({ auth }) => auth.role);

  const dispatch = useDispatch();

  useEffect(() => {
    userRole.is_client && dispatch(fetchClientMerchants(userInfo?.id));
    userRole.is_client_user &&
      dispatch(fetchClientUserMerchants(userInfo?.client_id));

    userRole.is_brand && dispatch(fetchBrandMerchants(userInfo?.id));
    userRole.is_brand_user &&
      dispatch(fetchBrandUserMerchants(userInfo?.brand_id));

    userRole.is_admin && dispatch(fetchMerchants());
  }, [dispatch, userRole, userInfo]);

  useEffect(() => {
    userRole.is_client && setUserMerchants(merchants.clientMerchants);
    userRole.is_client_user && setUserMerchants(merchants.clientUserMerchants);

    userRole.is_brand && setUserMerchants(merchants.brandMerchants);
    userRole.is_brand_user && setUserMerchants(merchants.brandUserMerchants);

    userRole.is_admin && setUserMerchants(merchants?.merchants);
  }, [userRole, merchants]);

  return (
    <MainContent>
      <MerchantsTable
        merchants={userMerchants}
        plans={plans}
        merchantsLoading={merchants?.merchantsLoading}
      />
    </MainContent>
  );
};

export default MerchantManagement;
