import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useClickOutside } from "@vms-admin/hooks";
import {
  Dashboard,
  Organisation,
  Customer,
  Voucher,
  Subscription,
  Social,
  Loyalty,
  AdminPanel,
} from "@vms-admin/icons";

export const Sidebar = ({ items }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const targetEl = useRef();
  const selectedNavItem = sessionStorage.getItem("selected-nav");
  const handleActiveElement = useCallback((e, index) => {
    e.preventDefault();
    setSelectedItem(index);
    sessionStorage.setItem("selected-nav", index);
  }, []);

  useClickOutside(targetEl, () => setSelectedItem(null));

  const icons = {
    Dashboard,
    Organisation,
    Customer,
    Voucher,
    Subscription,
    Social,
    Loyalty,
    AdminPanel,
  };

  return (
    <>
      {items?.length > 0 ? (
        <nav className="sidebar">
          <div className="sidebar-sticky" ref={targetEl}>
            {items?.map(({ title, items }, index) => {
              let Icon = icons[`${title.split(" ").join("")}`];
              return (
                <div key={title}>
                  {items.filter(({ show }) => show)?.length > 0 && (
                    <ul className="navbar-nav">
                      <li
                        className={`nav-item dropdown ${
                          index === parseInt(selectedNavItem) && `active`
                        } `}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {Icon && <Icon />}
                          <Link
                            className="nav-link inner-menu-toggle"
                            to="/"
                            id="navbarDropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={(e) => handleActiveElement(e, index)}
                          >
                            {title}
                          </Link>
                        </div>
                        <div
                          className={`inner-menu ${
                            index === selectedItem && `active`
                          }`}
                          aria-labelledby="navbarDropdown"
                        >
                          {items.map(({ title, path, show }) => (
                            <div key={title}>
                              {show && (
                                <Link
                                  className="inner-menu-item"
                                  to={`/${path}`}
                                >
                                  {title}
                                </Link>
                              )}
                            </div>
                          ))}
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        </nav>
      ) : (
        <Skeleton
          count={5}
          width={100}
          height={15}
          containerClassName="sidebar"
          style={{ marginLeft: 50, marginTop: 100 }}
        />
      )}
    </>
  );
};

export default Sidebar;
