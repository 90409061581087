import axios from "axios";
import API_URL from "../../api/api.constants";
import {
  FETCH_USER_INFO_SUCCESS,
  LOGGED_IN_SUCCESS,
  LOGGED_OUT_SUCCESS,
  SET_USER_ROLE_SUCCESS,
} from "./actions.constants";

const loginSuccess = () => ({
  type: LOGGED_IN_SUCCESS,
  payload: {
    isAuthenticated: true,
  },
});

const logoutSuccess = () => ({
  type: LOGGED_OUT_SUCCESS,
  payload: {
    isAuthenticated: false,
  },
});

export const loginUser = (emailAddress, password) => (dispatch) => {
  let config = {
    method: "POST",
    headers: {
      email: emailAddress,
      password: password,
    },
  };
  return axios(API_URL.login, config)
    .then((res) => {
      if (res?.headers?.["access-token"]) {
        dispatch(loginSuccess(res));
        localStorage.setItem("access-token", res.headers["access-token"]);
        localStorage.setItem("refresh-token", res.headers["refresh-token"]);
      }
      return res;
    })
    .catch((error) => error);
};

export const logoutUser = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  let config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios(API_URL.logout, config)
    .then((res) => res.status === 200 && res.data)
    .then(() => {
      localStorage.removeItem("access-token");
      dispatch(logoutSuccess());
      return Promise.resolve();
    })
    .catch((error) => console.log("error", error));
};

export const fetchUserInfo = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.userInfo}`, config)
    .then((res) => res.status === 200 && res.data)
    .then((data) => dispatch({ type: FETCH_USER_INFO_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const setUserRole = (role) => (dispatch) =>
  dispatch({ type: SET_USER_ROLE_SUCCESS, role });
