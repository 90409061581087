import React from "react";

export const ChevronDown = ({ width = 11, height = 7 }) => (
  <svg
    className="menu-arrow"
    xmlns="http://www.w3.org/2000/svg"
    width="12.445"
    height="7.4"
    viewBox="0 0 12.445 7.4"
  >
    <path
      id="Path_25570"
      data-name="Path 25570"
      d="M23.853,17.653a.567.567,0,0,1,0,.764l-5.5,5.918a.476.476,0,0,1-.71,0l-5.5-5.918a.57.57,0,0,1,0-.764.477.477,0,0,1,.71,0L18,23.195,19.4,21.68l3.739-4.027a.476.476,0,0,1,.71,0Z"
      transform="translate(-11.756 -17.294)"
      fill="#8e8e8e"
      stroke="#8e8e8e"
      strokeWidth="0.4"
    />
  </svg>
);
