import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Categories, MainContent, Grid } from "@vms-admin/components";
import {
  createBrandCategory,
  deleteBrandCategory,
  editBrandCategory,
  fetchBrandCategories,
} from "../../redux/actions";
import toast from "react-hot-toast";
import { dismissToast, somethignWentWrong } from "../../helpers";

export const BrandSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setEditIsModalOpen] = useState(false);
  const brandCategories = useSelector(({ brands }) => brands.categories);
  const dispatch = useDispatch();

  const handleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const handleEditModal = useCallback(
    () => setEditIsModalOpen(!isEditModalOpen),
    [isEditModalOpen]
  );

  const handleCreateBrandCategory = useCallback(
    (data) => {
      dismissToast();
      dispatch(createBrandCategory(data))
        .then((res) => {
          if (res.status === 500) {
            setIsModalOpen(true);
            toast.error("Category Name already exists");
          } else {
            setIsModalOpen(false);
            dispatch(fetchBrandCategories());
            toast.success(`${data.categoryName} has been created Successfully`);
          }
        })
        .catch(() => {
          somethignWentWrong();
        });
    },
    [dispatch]
  );

  const handleDeleteBrandCategory = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteBrandCategory(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchBrandCategories());
            toast.success("Brand Category has been deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleEditBrandCategory = useCallback(
    (id, categoryName) => {
      dismissToast();
      dispatch(editBrandCategory(id, categoryName))
        .then((res) => {
          if (res.status === 500) {
            setEditIsModalOpen(true);
            toast.error("Category Name already exists");
          } else {
            setEditIsModalOpen(false);
            dispatch(fetchBrandCategories());
            toast.success(`${categoryName} has been updated Successfully`);
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  useEffect(() => dispatch(fetchBrandCategories()), [dispatch]);

  return (
    <MainContent>
      <Grid>
        <Categories
          title="Brand Categories"
          categories={brandCategories}
          type="Brand"
          createCategory={handleCreateBrandCategory}
          deleteCategory={handleDeleteBrandCategory}
          editCategory={handleEditBrandCategory}
          isModalOpen={isModalOpen}
          isEditModalOpen={isEditModalOpen}
          handleIsEditModal={handleEditModal}
          handleModal={handleModal}
        />
      </Grid>
    </MainContent>
  );
};

export default BrandSettings;
